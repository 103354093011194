import React from 'react';
import Projects from './blocks/projects/Projects'

const Portfolio = () => {
    return (
        <>
            <Projects/>
        </>
    );
};

export default Portfolio;