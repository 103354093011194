import Box from '@mui/material/Box'
import React from 'react'

interface IContainerLayouts {
    children: React.ReactNode,
}

const ContainerLayouts: React.FC<IContainerLayouts> = ({children}) => {
    const [screenWidth, setScreenWidth] = React.useState<number>(window.screen.width)

    React.useEffect(() => {
        window.addEventListener('resize', () => setScreenWidth(window.screen.width))
        return window.removeEventListener('resize', () => setScreenWidth(window.screen.width))
    })

    const container = screenWidth * 0.9
    return (
        <Box
            component='div'
            display='flex'
            maxWidth='1470px'
            padding='0 20px'
            width={container}
            height='100%'
            m='0 auto'
        >
            {children}
        </Box>
    )
}


export default ContainerLayouts

