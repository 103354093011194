import React from 'react';
import rightSmallBlock from '../../../../icons/Introduction/bottomSmallImage.png'
import leftSmallBlock from '../../../../icons/Introduction/topSmallImage.png'
import rightBigBlock from '../../../../icons/Introduction/topBigImage.png'
import leftBigBlock from '../../../../icons/Introduction/bottomBigImage.png'

import {Box, Typography} from '@mui/material'
import ContainerLayouts from '../../../../layouts/ContainerLayouts';
import FadeLayouts from '../../../../layouts/FadeLayouts';


const introductionList = [
    {title: 'Creative', image: leftSmallBlock, className: 'block__left--small'},
    {title: 'Modern UI', image: rightBigBlock, className: 'block__right--big'},
    {title: 'User friendly', image: leftBigBlock, className: 'block__left--big'},
    {title: 'Good UX', image: rightSmallBlock, className: 'block__right--small'}
]

const Introduction = () => {
    const changeCursor = (e: any, title: string, index: number) => {
        const block = document.getElementById(title + index) as HTMLElement
        const cursor = document.getElementById(title) as HTMLElement

        const mouseY = e.clientY - cursor.getBoundingClientRect().top
        const mouseX = e.clientX - cursor.getBoundingClientRect().left
        cursor.style.transform = `translate(${mouseX}px, ${mouseY}px)`;
        block.style.cursor = 'none'
        cursor.style.cursor = 'none'

    }

    return (
        <ContainerLayouts>
            <FadeLayouts fade='introduction'>
                <Box component='div' width='100%'>
                    <Box component='div' id='introduction'>
                        <Typography id='title'
                                    className='title text-shadow'
                                    lineHeight='1.1' fontWeight={600} fontSize='2.75rem'
                                    textAlign='center'>Проектное представление</Typography>
                    </Box>
                    <Box component='div' className='introduction__wrapper'>
                        {introductionList.map((element, index) => (
                            <Box component='div' key={element.title} onMouseMove={(e) => changeCursor(e, element.title, index)}
                                 position='relative'>
                                <Box component='div' id={element.title + index} className={element.className}>
                                    <img className='introduction__img' src={element.image} alt="изображение"/>
                                </Box>
                                <Box component='div' id={element.title} zIndex={10}
                                     className='introduction__title'>{element.title}</Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </FadeLayouts>
        </ContainerLayouts>
    );
};

export default Introduction;
