import React from 'react';
import {Box, Typography} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import {Link} from "react-router-dom";
import ContainerWrapper from "../../../../../wrappers/containerWrapper/ContainerWrapper";

const WhyOurAdministrators = () => {
    return (
        <ContainerWrapper title='Почему наши аминистраторы' fadeLayout='administrators'
                          breadcrumbs={<Breadcrumbs aria-label="breadcrumb">
                              <Link to='/AboutUs'>
                                  <Typography color="text.primary" className='breadcrumbs'>
                                      О нас
                                  </Typography>
                              </Link>
                              <Typography variant='subtitle2' color='text.secondary'>Администраторы</Typography>
                          </Breadcrumbs>}>
            <Box component='div' display='flex' gap='10px' flexDirection='column'>
                <Typography className='subtitle'>
                    Администраторы - это сердце и душа нашей компании. Они отвечают за безопасность и
                    эффективность нашей инфраструктуры, обеспечивая бесперебойную работу наших систем и
                    сервисов.
                </Typography>
                <Typography>
                    Они ответственны за установку, настройку и обслуживание серверов, компьютеров и сетевого
                    оборудования, а также за управление базами данных и защиту информации нашей компании от
                    внешних угроз.
                </Typography>
                <Typography>
                    Администраторы являются важным звеном в нашей команде и обеспечивают бесперебойную работу
                    нашей IT инфраструктуры, что позволяет нам оставаться конкурентоспособными в нашей отрасли.
                    Без их профессионализма и преданности нашей компании было бы гораздо сложнее достичь наших
                    целей и обеспечить нашим клиентам лучший сервис.
                </Typography>
            </Box>
        </ContainerWrapper>

    );
};

export default WhyOurAdministrators;