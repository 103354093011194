import React from 'react';
import {
    Box,
    Typography,
    Paper,
    Slider,
    Snackbar,
    useMediaQuery,
    Chip,
} from '@mui/material';
import {useFormik} from "formik";
import * as Yup from "yup";
import {createOrder} from "../../HTTP/controllers/orderController"
import MuiAlert from '@mui/material/Alert';
import {theme} from "../../theme/theme";
import {useSnackbar} from "../../hooks/useSnackbar";
import {development} from "../../pages/contacts/blocks/ourContacts/ourContacts.interfaces";
import {styled} from "@mui/material/styles";

import {IForm} from "./form.interfaces";
import {CustomTextField} from './UserDataForm'
import UserDataForm from './UserDataForm'
import FormButtonSubmit from "./FormButtonSubmit";

const list: development[] = [
    {title: 'Android', id: 1},
    {title: 'iOS', id: 2},
    {title: 'Web', id: 3},
]

const sliderMarksDesktop = [
    {
        value: 5e5,
        label: '500 тыс. ₽'
    },
    {
        value: 0,
        label: '0 ₽',
    },
    {
        value: 1e6,
        label: '1 млн. ₽',
    },
    {
        value: 2e6,
        label: '2 млн. ₽',
    },
    {
        value: 3e6,
        label: '3 млн. ₽',
    },
]

const sliderMarksMobile = sliderMarksDesktop.slice(1, sliderMarksDesktop.length)


const CustomChip = styled(Chip)(() => ({
    transition: 'all .1s linear',
    fontSize: '1rem',
    borderRadius: '8px',
    boxShadow: '0 5px 5px rgba(0, 0, 0, 0.10)'
}))

const CustomRangeSlider = styled(Slider)(({theme}) => ({
    '& .MuiSlider-valueLabel': {
        fontSize: '0.9rem',
        fontWeight: 600,
        top: -6,
        backgroundColor: 'rgba(109, 119, 134, 0.40);',
        color: 'white',
    },
    '& .MuiSlider-markLabel': {
        color: theme.palette.text.primary,
        '&.MuiSlider-markLabelActive': {
            color: theme.palette.primary.main,
        },
    },
}))

const Form: React.FC<IForm> = ({
                                   titleColor,
                                   subtitleColor,
                                   textFieldVariant = 'outlined',
                                   textFieldBackground = 'white',
                                   square
                               }) => {
    const breakpointSM = useMediaQuery(theme.breakpoints.down('sm'))
    const [activeDevelopments, setActiveDevelopments] = React.useState<string[]>([])
    const [slider, setSlider] = React.useState<number[]>([0, 300000]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const {closeSnackbar, openSnackbar, snackbar} = useSnackbar()
    const changeSlider = (event: Event, value: number | number[]) => {
        setSlider(value as number[]);
    };
    const formik = useFormik({
        initialValues: {
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
            text: ''
        },
        validationSchema: Yup.object({
            email: Yup
                .string()
                .email('')
                .max(50)
                .required('Не является почтой'),
            firstName: Yup
                .string()
                .max(15)
                .required('Данное поле обязательно'),
            lastName: Yup
                .string()
                .max(50)
                .required('Данное поле обязательно'),
            phone: Yup
                .string()
                .required('Данное поле обязательно')
        }),

        onSubmit: () => {
            const {email, lastName, firstName, text, phone} = formik.values;
            (async () => {
                setIsLoading(true)
                try {
                    const response = await createOrder({
                        email,
                        name: `${firstName} ${lastName}`,
                        message: text,
                        phone,
                        cost_start: slider[0],
                        cost_end: slider[1],
                        ios: activeDevelopments.includes('iOS'),
                        web: activeDevelopments.includes('Web'),
                        android: activeDevelopments.includes('Android')
                    })
                    if (response.status === 201) {
                        openSnackbar({message: 'Ваша заявка была успешно создана', severity: "success"})
                    }
                } catch (e) {
                    openSnackbar({message: 'Что-то пошло не так', severity: "error"})
                } finally {
                    setIsLoading(false)
                }
            })()
        }
    });

    const sliderFormat = (value: number) => {
        let format = value / 1000 + " тыс."
        if (parseInt(format) >= 1000) {
            format = (parseInt(format) / 1000) + " млн."
        }
        return `${format} ₽`;
    }

    const removeDevelopment = (title: string) => {
        setActiveDevelopments(activeDevelopments.filter((element) => element !== title))
    }
    const changeDevelopment = (title: string) => {
        if (isActiveDevelopment(title)) {
            removeDevelopment(title)
            return
        }
        setActiveDevelopments([...activeDevelopments, title])
    }
    const isActiveDevelopment = (title: string) => {
        return activeDevelopments.includes(title)
    }

    return (
        <>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={4000}
                onClose={closeSnackbar}
            >
                <MuiAlert onClose={closeSnackbar} variant='filled' elevation={9}
                          sx={{width: '100%', backgroundColor: snackbar.severity, color: 'white'}}>
                    {snackbar.message}
                </MuiAlert>
            </Snackbar>
            <form onSubmit={formik.handleSubmit}>
                <Box component='div' mb='10px' zIndex='1' position='relative'>
                    <Typography className='title text-shadow' textAlign='left' variant='h2' color={titleColor}>Готовы
                        создать свой
                        проект?</Typography>
                </Box>
                <Box component='div' mb='10px' zIndex='1' position='relative'>
                    <Typography className='subtitle' textAlign='left' color={subtitleColor}>Тип проекта</Typography>
                </Box>
                <Box component='div' display='flex' gap='10px' mb='10px'>
                    {list.map(({title, id}) => (
                        <CustomChip key={id}
                                    variant={isActiveDevelopment(title) ? 'filled' : 'outlined'}
                                    color='primary'
                                    onClick={() => changeDevelopment(title)} label={title}/>
                    ))}
                </Box>
                <Box component='div' mb='10px'>
                    <UserDataForm formik={formik} textFieldBackground={textFieldBackground} square={square}
                                  textFieldVariant={textFieldVariant}/>
                </Box>
                <Box component='div' mb='10px' zIndex='1' position='relative'>
                    <Typography className='subtitle' textAlign='left' color={subtitleColor}>Ваш бюджет</Typography>
                </Box>
                <Box component='div' p='0 10px' zIndex='1' position='relative'>
                    <CustomRangeSlider
                        value={slider}
                        onChange={changeSlider}
                        step={5000}
                        getAriaValueText={sliderFormat}
                        valueLabelFormat={sliderFormat}
                        min={0}
                        max={3e6}
                        valueLabelDisplay="auto"
                        marks={breakpointSM ? sliderMarksMobile : sliderMarksDesktop}
                        defaultValue={[0, 5e5, 1e6, 2e6, 3e6]}
                        disabled={isLoading || formik.isSubmitting}
                    />
                </Box>
                <Box component='div' mb='15px' zIndex='1' position='relative'>
                    <Paper elevation={9} sx={{
                        backgroundColor: textFieldBackground,
                        borderTopRightRadius: '8px',
                        borderTopLeftRadius: '8px'
                    }} square={square}>
                        <CustomTextField
                            fullWidth
                            value={formik.values.text}
                            onChange={formik.handleChange}
                            label='Сообщение'
                            name='text'
                            multiline
                            rows={5}
                            type='text'
                            variant={textFieldVariant}
                            color='primary'
                            size={breakpointSM ? 'small' : 'medium'}
                        />
                    </Paper>
                </Box>
                <FormButtonSubmit isSubmitting={formik.isSubmitting} buttonTitle='Создать заявку'
                                  isLoading={isLoading}/>
            </form>
        </>
    );
};

export default Form;
