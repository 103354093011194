import React from 'react';
import {Box, Typography, useMediaQuery} from "@mui/material";
import cards from "../../../../icons/cards.png";
import ContainerLayouts from '../../../../layouts/ContainerLayouts'
import FadeLayouts from "../../../../layouts/FadeLayouts";
import {theme} from "../../../../theme/theme";


const Main = () => {
    const breakpoint = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        <ContainerLayouts>
            <FadeLayouts fade='main'>
                <Box component='div' width='100%' id='main' display='flex' justifyContent='center' flexWrap='wrap' alignItems='center'
                     mt='100px' mb={breakpoint ? '20px' : '0'}>
                    <Box component='div' className='main__box'>
                        <Typography className='main__title text-shadow' component='span' fontSize='3.625rem' fontWeight='700'
                                    color='text.secondary'>
                            Создайте <br/> свой проект Сегодня <br/> вместе с&nbsp;
                            <Typography className='main__logo' component='span' fontSize='3.625rem' fontWeight='700'
                                        color='primary.main'>IT-POWER</Typography>
                        </Typography>
                    </Box>
                    <Box component='div' className='box__cards'>
                        <img className='box__img' src={cards} alt="Картинка"/>
                    </Box>
                </Box>
            </FadeLayouts>
        </ContainerLayouts>
    );
};

export default Main;