import { createSlice } from '@reduxjs/toolkit'
import {IInitialState} from "./headerReducer.interfaces";




const initialState: IInitialState = {
    link:''
}

export const counterSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        changeLink: (state, {payload})=>{
            return {...state, link: payload}
        }
    },
})

export const { changeLink } = counterSlice.actions

export default counterSlice.reducer