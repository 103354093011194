import React from 'react';
import {Box, Typography} from "@mui/material";
import ContainerWrapper from "../../../../../../wrappers/containerWrapper/ContainerWrapper";
import Graphic from "./graphic/Graphic";


const Monitoring = () => {
    return (
        <ContainerWrapper title='Мониторинг' fadeLayout='monitoring'>
            <Typography className='subtitle'>
                Наши системы отслеживают около 10 000 различных показателей производительноти, делают резервные копии
                всех необходимых данных, а сотрудники лично проверяют, что автоматика не подвела
            </Typography>
            <Box component='div' display='flex' flexWrap='wrap'>
                <Box component='div' maxWidth='50%' flex='1' width='100%'>
                    <Graphic
                        title='Пользователей / Запросов'
                        dataOne={[6000, 3540, 6704, 1030, 10000, 7043, 8000, 2000]}
                        colorOne={['rgb(128,86,248)', 'rgba(94, 53, 177, 0.1)']}

                        dataTwo={[2000, 2343, 5000, 7600, 9000, 12000, 5204, 2400]}
                        colorTwo={['rgba(30, 136, 229, 1)', 'rgba(30, 136, 229, 0.2)']}

                        labels={['1.10.2022', '2.10.2022', '3.10.2022', '4.10.2022', '5.10.2022', '6.10.2022', '7.10.2022', '8.10.2022']}/>
                </Box>
                <Box component='div' maxWidth='50%' flex='1' width='100%'>
                    <Graphic
                        title="Свободная память"
                        dataOne={[4, 2, 1, 2, 6, 1, 9, 1]}
                        colorOne={['rgb(128,86,248)', 'rgba(94, 53, 177, 0.1)']}

                        dataTwo={[4, 10, 4, 4, 24, 6, 8, 5]}
                        colorTwo={['rgba(30, 136, 229, 1)', 'rgba(30, 136, 229, 0.2)']}

                        labels={['1.10.2022', '2.10.2022', '3.10.2022', '4.10.2022', '5.10.2022', '6.10.2022', '7.10.2022', '8.10.2022']}
                    />
                </Box>
            </Box>

        </ContainerWrapper>
    );
};

export default Monitoring;