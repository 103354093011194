import React from 'react';
import {Box, useMediaQuery} from '@mui/material';
import ContainerLayouts from '../../../../layouts/ContainerLayouts';
import FadeLayouts from '../../../../layouts/FadeLayouts';
import {theme} from "../../../../theme/theme";
import Form from '../../../../rootBlocks/form/Form'
import ContactsCompany from '../../../../rootBlocks/contactsCompany/ContactsCompany'
import ThreeModule from '../canvas/ThreeModule'

const OurContacts = () => {
    const breakpointMD = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <ContainerLayouts>
            <FadeLayouts fade='contacts'>
                <Box component='div' id='contacts' width='100%' mt='100px' display='flex'
                     justifyContent={'center'}
                     flexDirection={'row'}>

                    {/*<ThreeModule/>*/}

                    <Box component='div'>
                        <Form subtitleColor='text.primary' titleColor='text.secondary'/>
                        <ContactsCompany color='text.secondary'/>
                    </Box>
                </Box>
            </FadeLayouts>
        </ContainerLayouts>
    )
        ;
};

export default OurContacts;
