import React from 'react';
import OurContacts from "./blocks/ourContacts/OurContacts";
const Contacts = () => {
    return (
        <>
            <OurContacts/>
        </>
    );
};

export default Contacts;