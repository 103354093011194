import React from 'react';
import {Fade} from '@mui/material'
import useObserver from "../hooks/useObserver";

interface IFadeLayouts {
    fade: string,
    children: JSX.Element
}

const FadeLayouts: React.FC<IFadeLayouts> = ({fade, children}) => {
    const {view} = useObserver(fade)
    return (
        <Fade in={view} timeout={500}>
            {children}
        </Fade>
    );
};

export default FadeLayouts;