import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import {ThemeProvider} from '@mui/material/styles';
import {theme} from './components/theme/theme'
import {Provider}from 'react-redux'
import {store} from './components/store/store'
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <App/>
        </Provider>
    </ThemeProvider>
)
;

