import {server} from "../HTTP";
import {IBody} from "../http.interfaces";

class OrderService {
    async createOrder(body:IBody) {
        return await server.post('/create_order/', body)
    }

}



export default new OrderService();