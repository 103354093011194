import React from 'react';
import {
    Box,
    Typography,
    Paper,
    Collapse,
    TextField,
    useMediaQuery
} from '@mui/material'
import {theme} from '../../../../theme/theme'
import FilterMenu from '../filterMenu/FilterMenu'
import ContainerLayouts from '../../../../layouts/ContainerLayouts'
import {
    AsistImg, Calendar1, Calendar2, Calendar3, Calendar4, Calendar5,
    Gastronorma1,
    Gastronorma2,
    Gastronorma3, Gastronorma4,
    KomandirovkaImg1, Slog1, Slog2, Slog3, Slog4, Slog5, SopSop1, SopSop2, SopSop3, SopSop4, SopSop5,
    TrackerImg
} from "../../../startPage/blocks/portfolio";
import ProjectCard from '../projectCard/ProjectCard'
import {styled} from "@mui/material/styles";
import {IProject} from "../projectCard/projectCard.interfaces";
import {IMaskInput} from "react-imask";
import FadeLayouts from '../../../../layouts/FadeLayouts';

export const mock: IProject[] = [
    {
        title: "NaSutkiCalendar",
        img: [Calendar1, Calendar2, Calendar3, Calendar4, Calendar5],
        description: "Мобильное приложение для риэлторов и людей сдающих квартиры посуточно, в котором реализован функционал управления заселением постояльцев, просмотра черного списка клиентов, ведения нескольких квартир, звонков клиенту из приложения, а также просмотр доходов за  каждый месяц.",
        dateAt: 2021
    },
    {
        title: "Gastronorma",
        img: [Gastronorma1, Gastronorma2, Gastronorma3, Gastronorma4],
        description: "Мобильное приложение для ресторанов и кафе. Подойдет как для больших сетей, так и для маленьких заведений. В приложении реализованы функции доставки и самовывоза, а также заказа на месте. Приложение Gastronorma - современная замена бумажным блокнотам с интегрированной системой лояльности.",
        dateAt: 2022
    },
    {
        title: "Sop Sop",
        img: [SopSop1, SopSop2, SopSop3, SopSop4, SopSop5],
        description: "Мобильное приложение для beauty-мастеров, призванное упростить ведение клиентской записи и привлекать больше новых клиентов. В приложении можно выстроить удобный график, вести клиентскую базу и следить за записями на услуги в удобном таймлайне.",
        dateAt: 2022
    },
    {
        title: "Slog",
        img: [Slog1, Slog2, Slog3, Slog4, Slog5],
        description: "Мобильные приложения для людей, которые хотят уменьшить свой экослед, сдавая упаковку и другое вторсырье на переработку. Приложения разработаны в сотрудничестве с компаниями по переработке отходов.",
        dateAt: 2022
    },
]

export const mock2: IProject[] = [
    {
        title: "KOMANDIROVKA.LIFE",
        img: KomandirovkaImg1,
        description: "Приложение, созданное для того, чтобы люди в командировке могли познакомиться с другими командировочными, находящимися в одном городе. В приложении пользователь может найти человека для совместного времяпрепровождения по интересам, возрасту и полу и начать с ним общение через чат, оплатив подписку.",
        dateAt: 2022
    },
    {
        title: "Work Tracker",
        img: TrackerImg,
        description: "Cистема учета рабочего времени сотрудников, разработанная для внедрения в компанию одного из наших клиентов. В системе реализован функционал Google календаря с расширенными аналитическими возможностями и возможностью разделять исполнителей задач и мероприятий по отдельным проектам, и группам должностей и обязанностей. Проект включает в себя административную панель настройки конкретной компании, в которой можно выставить роли администраторов, контролеров и исполнителей задач.",
        dateAt: 2022
    },
    {
        title: "ASIST",
        img: AsistImg,
        description: "Удобный интерфейс для поиска аналогов по заранее составленной смете. Приложение позволяет загрузить в него файл со списком необходимого оборудования по заранее скачанному шаблону и получить в ответ заполненный файл с аналогами.",
        dateAt: 2022
    },
]

export const CustomTextField = styled(TextField)(({theme}) => ({
    transition: 'all .1s linear',
    color: theme.palette.text.primary,
    '.MuiFormLabel-root': {
        color: theme.palette.text.primary,
    },
    '.MuiInputBase-root': {
        color: theme.palette.text.primary,
    },
    '.MuiOutlinedInput-notchedOutline': {
        border: 'none'
    },
}))

export const TextMaskCustom = React.forwardRef<HTMLElement, any>(
    function TextMaskCustom(props, ref) {
        const {onChange, ...other} = props;
        return (
            <IMaskInput
                {...other}
                mask="#000"
                definitions={{
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value: any) => onChange({target: {name: props.name, value}})}
            />
        );
    },
);

const Projects = () => {
    const breakpointMD = useMediaQuery(theme.breakpoints.down('md'))

    const [findInput, setFindInput] = React.useState<string>('')
    const [filterProjects, setFilterProjects] = React.useState<IProject[]>([...mock, ...mock2])

    const changeFilterProject = (array: any) => {
        setFilterProjects(array)
    }

    /**Функция для изменения инпута по поиску проектов в блоке: Фильтрация > Год создания
     * @param event
     * */
    const changeFindInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFindInput(event.currentTarget.value)
    }

    return (
        <ContainerLayouts>
            <FadeLayouts fade='portfolio'>
                <Box component='div' id='portfolio' width='100%' mt='100px'>
                    <Box component='div' mb='10px'>
                        <Typography className='title text-shadow' textAlign='center' variant='h2'>Разработанные
                            проекты</Typography>
                    </Box>
                    <Box component='div' mb='10px' maxWidth='1386px'>
                        <Paper elevation={9}>
                            <CustomTextField fullWidth onChange={(e) => changeFindInput(e)} value={findInput}
                                             label="Поиск"
                                             title='Поиск' variant="outlined" color='secondary'/>
                        </Paper>
                    </Box>
                    <Box component='div' display='flex' flexDirection={breakpointMD ? 'column' : 'row'} gap='10px'>
                        <FilterMenu findInput={findInput} changeFilterProject={changeFilterProject}/>
                        <Box component='div' maxWidth='1100px'>
                            <Collapse in={filterProjects.length !== 0}>
                                <Box component='div' display='flex' flex='1' gap='10px' flexWrap='wrap' mb='10px'
                                     justifyContent={breakpointMD ? 'center' : 'flex-start'}>
                                    {filterProjects.map((element) => (
                                        <ProjectCard key={element.title} element={element}/>
                                    ))}
                                </Box>
                            </Collapse>
                        </Box>
                    </Box>
                </Box>
            </FadeLayouts>
        </ContainerLayouts>
    );
};

export default Projects
