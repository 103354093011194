import React from 'react';
import ReactDOM from "react-dom";
import {Box,IconButton, Paper, Fade} from "@mui/material";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
const UndoButton = () => {
    const [view, setView] = React.useState<boolean>(false)
    React.useEffect(()=>{
        window.addEventListener('scroll', scroll)

        return () => window.removeEventListener('scroll', scroll)
    },[])

    const scroll = () => {
        const scrollY = document.documentElement.scrollTop
        console.log(scrollY)
        if(scrollY > 100){
            setView(true)
        }
        if(scrollY === 0){
            setView(false)
        }
    }
    const changeScroll = () => {
        window.scrollTo({
            top:0,
            behavior:'smooth'
        })
    }

    return ReactDOM.createPortal(
        <Fade in={view} timeout={300} unmountOnExit>
            <Paper onClick={changeScroll} elevation={9} className='undo'>
                <Box component='div'>
                    <IconButton color='info' size='large'>
                        <ExpandLessIcon sx={{color:'white'}} fontSize='medium'/>
                    </IconButton>
                </Box>
            </Paper>
        </Fade>, document.getElementById('undo') as HTMLElement)
}
export default UndoButton;