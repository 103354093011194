import React from 'react';
import {ICurrentService} from "./service.interfaces";
import {Box, Button, Typography} from "@mui/material";
import {NavLink as Link} from "react-router-dom";

const CurrentService: React.FC<ICurrentService> = ({element}) => {
    return (
        <Box component='div' p='20px 0'>
            <Box component='div' maxWidth='400px' bgcolor='#20232F' borderRadius='8px' p='15px' gap='20px'
                 height='max-content'>
                <Typography className='title text-shadow' textAlign='left' variant='h4' color='white'>
                    {element.title}
                </Typography>

                <Typography color='white'>
                    {element.subtitle}
                </Typography>
                <Box component='div' position='relative'  height='330px' display='flex'
                     justifyContent='center'
                     alignItems='center'
                     color='white'>
                    <Box component='div' position='absolute'>

                        <video style={{backgroundColor: 'transparent'}} width='300px' height='700px' autoPlay={true}
                               muted
                               loop={true}>
                            <source src='/Videos/0001-0100.webm' type='video/webm'/>
                        </video>
                    </Box>

                </Box>
                {/*<Link to={element.href}>*/}
                {/*    <Button variant='outlined' color='info' size='small'>*/}
                {/*        Узнать подробности*/}
                {/*    </Button>*/}
                {/*</Link>*/}
            </Box>
        </Box>
    );
};

export default CurrentService;