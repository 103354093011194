import {Box, Typography, Fade} from '@mui/material';
import React from 'react';
import {ellipseList} from "./lineNavigation.interfaces";
import ReactDOM from 'react-dom'
import {useTypeSelector} from "../../hooks/useTypeSelector";

const makePercantage = (value: number, documentHeight: number) => {
    return ((value * 100) / (documentHeight))
}
const LineNavigation = () => {
    const link = useTypeSelector(({headerReducer})=>headerReducer.link)
    const [lineHeight, setLineHeight] = React.useState<number>(0)
    const [dinamicList, setDinamicList] = React.useState<ellipseList[]>([])
    const [isVisible, setIsVisible] = React.useState<boolean>(false)

    React.useEffect(() => {
        const documentHeight = document.documentElement.offsetHeight - window.innerHeight
        const titles = document.querySelectorAll('#title')
        if (titles.length) {
            const coordinates = Array.from(titles).map((title) => {
                const nodeCoordinate = title.getBoundingClientRect().top + document.documentElement.scrollTop
                return {
                    title: title.innerHTML,
                    isActive: false,
                    percentage: makePercantage(nodeCoordinate - 120, documentHeight),
                    nodeCoordinate: nodeCoordinate - 100
                }
            })
            coordinates.unshift({title: 'Начало', isActive: false, percentage: -1, nodeCoordinate: 0})
            coordinates.push({
                title: 'Контакты',
                isActive: false,
                percentage: 99,
                nodeCoordinate: documentHeight
            })

            const bindScroll = scroll.bind(null, coordinates, documentHeight)
            setDinamicList(coordinates)
            window.addEventListener('scroll', bindScroll)
            return () => window.removeEventListener('scroll', bindScroll)
        } else {
            setIsVisible(false)
        }
    }, [document.documentElement.offsetHeight, link])


    const changeAttitude = (nodeCoordinate: number) => {
        window.scrollTo({
            top: nodeCoordinate,
            behavior: "smooth"
        })
    }
    const scroll = (coordinates: ellipseList[], documentHeight: number) => {
        const scrollY = document.documentElement.scrollTop
        const lineHeight = makePercantage(scrollY, documentHeight)
        setDinamicList(coordinates.map((element: any) => element.percentage <= lineHeight ? {
            ...element,
            isActive: true
        } : {...element, isActive: false}))
        setLineHeight(lineHeight)
        if (scrollY > 30) {
            setIsVisible(true)
            return
        }
        if (scrollY === 0) {
            setIsVisible(false)
            return;
        }
    }

    return ReactDOM.createPortal(
        <Fade in={isVisible} unmountOnExit>
            <Box component='div' className='navigation__underline'>
                {dinamicList.map((element) => (
                    <Box component='div' key={element.title} display='flex' alignItems='center'>
                        <Box component='div' onClick={() => changeAttitude(element.nodeCoordinate)} key={element.percentage}
                             className={element.isActive ? 'navigation__ellipse navigation__ellipse--active' : 'navigation__ellipse'}
                             top={element.percentage + '%'}></Box>
                        <Typography
                            className={element.isActive ? 'navigation__title navigation__title--active' : 'navigation__title'}
                            top={element.percentage - 5 + '%'}
                            position='absolute'>{element.title}</Typography>
                    </Box>
                ))}
                <Box component='div' className='navigation__line' height={lineHeight + "%"}></Box>
            </Box>
        </Fade>, document.getElementById('navigation_line') as HTMLElement)
}

export default LineNavigation;