import React from "react";
import './index.scss'
import {Route, Routes, BrowserRouter} from "react-router-dom";
import {StartPage, AboutUs, Contacts, Portfolio, CurrentWork} from './components/pages/index'
import {Development, Services, OurAdministrators} from './components/pages/AboutUs/learnMore/index'
import {Header, LineNavigation, UndoButton} from "./components/rootBlocks";
import {useMediaQuery, Box} from "@mui/material";
import {theme} from "./components/theme/theme";

const App: React.FC = () => {
    const breakpointMD = useMediaQuery(theme.breakpoints.down('md'));
    const breakpointSM = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Box component='div' position='relative' minHeight='100vh'>
            <BrowserRouter>
                <Header/>
                <Routes>
                    <Route path="/" element={<StartPage/>}/>

                    <Route path="/AboutUs" element={<AboutUs/>}/>
                    <Route path="/AboutUs/administrators" element={<OurAdministrators/>}/>
                    <Route path="/AboutUs/development" element={<Development/>}/>
                    <Route path="/AboutUs/services" element={<Services/>}/>

                    <Route path="/Portfolio" element={<Portfolio/>}/>
                    <Route path="/Portfolio/:id" element={<CurrentWork/>}/>

                    <Route path="/Contacts" element={<Contacts/>}/>

                </Routes>
            </BrowserRouter>
            {!breakpointMD && <LineNavigation/>}
            {breakpointSM && <UndoButton/>}
        </Box>
    );
}

export default App;