import React from 'react';
import ContainerLayouts from "../../../../layouts/ContainerLayouts";
import FadeLayouts from "../../../../layouts/FadeLayouts";
import {Box, Typography} from "@mui/material";

const whyWeList = [
    {
        title: 'Клиентоориентированность',
        text: ' Мы стремимся создавать долгосрочные отношения с нашими клиентами и предлагать решения, которые наиболее полно удовлетворяют их потребности. Мы готовы работать вместе с нашими клиентами для достижения общих целей и успеха.'
    },
    {
        title: 'Пользовательский опыт',
        text: 'Мы понимаем, что успех любого технологического продукта зависит от его удобства и простоты использования. Поэтому мы стараемся создавать продукты и решения, которые будут максимально удобны для конечных пользователей.'
    },
    {
        title: 'Индивидуальный подход',
        text: 'Мы стремимся понимать уникальные потребности каждого нашего клиента и предлагать индивидуальные решения, которые наилучшим образом соответствуют их бизнес-задачам и целям.'
    },
    {
        title: 'Качество',
        text: 'Мы убеждены, что качество наших продуктов и услуг должно быть на самом высоком уровне. Мы стремимся обеспечивать максимальное качество наших решений, чтобы наши клиенты могли быть уверены в их надежности и эффективности.'
    },
    {
        title: 'Инновации',
        text: 'Мы постоянно следим за новыми технологиями и тенденциями в отрасли, чтобы быть в курсе последних инноваций и применять их в наших проектах. Мы готовы предложить инновационные решения для улучшения бизнес-процессов наших клиентов.'
    },
    {
        title: 'Прозрачность',
        text: 'Мы ценим честность и прозрачность во всех наших деловых отношениях. Мы стараемся поддерживать открытую коммуникацию со всеми нашими клиентами и всегда готовы обсудить любые вопросы, которые могут возникнуть в процессе работы.'
    }

]

const WhyWe = () => {
    return (
        <ContainerLayouts>
            <FadeLayouts fade='why__we'>
                <Box component='div' id='why__we' width='100%' mt='100px'>
                    <Box component='div' mb='10px'>
                        <Typography className='title text-shadow' textAlign='center' variant='h2'>Почему
                            мы?
                        </Typography>
                    </Box>
                    <Box component='div' display='flex' flexWrap='wrap' columnGap='20px' rowGap='10px'>
                        {whyWeList.map((element, index) => (
                            <Box key={element.title} component='div' width='calc(50% - 20px)' className='why__block'
                                 minWidth='400px'>
                                <Typography className='subtitle'
                                            lineHeight='1.5' fontWeight={600}
                                            fontSize='0.9rem'
                                            color='text.primary' align='left'>
                                    <Typography className='why__title' component='span' color='black' fontWeight='800'>
                                        {index + 1}.&nbsp;
                                    </Typography>
                                    <Typography className='why__title' component='span' color='black' fontWeight='800'>
                                        {element.title}:&nbsp;
                                    </Typography>
                                    {element.text}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </FadeLayouts>
        </ContainerLayouts>
    );
};

export default WhyWe;