import React from 'react';
import ContainerLayouts from '../../../../layouts/ContainerLayouts'
import FadeLayouts from "../../../../layouts/FadeLayouts";
import {Box, Typography, Button, useMediaQuery, Paper} from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {IAboutUsInfo} from "./aboutUs.interfaces";
import {NavLink as Link} from "react-router-dom";
import {changeLink} from '../../../../store/reducers/headerReducer/headerReducer'
import {useAppDispatch} from "../../../../hooks/useTypeSelector";

const aboutUsInfo: IAboutUsInfo[] = [
    {
        id: 'subtitle',
        title: 'Разработанных проектов',
        quantity: '26',
        about: 'Приложения, разработанные для частных клиентов: CRM/ERP/PMS системы, системы автоматизации технологических процессов, аналитические системы, мобильные приложения iOS/Android и OpenAPI приложения.',
        elevation: 0
    },
    {
        id: 'subtitle',
        title: 'Довольных клиентов',
        quantity: '150+',
        about: 'Помимо разработки Мы занимается настройкой серверного оборудования, сетевым администрированием, IT-консалтингом и обеспечением информационной безопасности, в чем успел заработать хорошую репутацию у своих клиентов.',
        elevation: 1
    },
    {
        id: 'subtitle',
        title: 'Лет опыта',
        quantity: '11',
        about: 'Проект IT-Power существует с 2011 года и имеет больше 5000+ бизнес-кейсов в своём портфеле, среди которых обязательно найдётся и Ваш!',
        elevation: 2
    },
]


const getNodeCoordinates = (node: HTMLElement) => {
    return node.getBoundingClientRect().top + document.documentElement.scrollTop - 300
}

const AboutUs: React.FC = () => {
    const breakpoint = useMediaQuery(('(max-width:1622px)'));
    const breakpointSM = useMediaQuery(('(max-width:1200px)'));
    const [height, setHeight] = React.useState<number>(0)
    const [coordinatesNode, setCoordinatesNode] = React.useState<number[]>([])

    const dispatch = useAppDispatch()

    React.useEffect(() => {
        const elements: NodeListOf<HTMLElement> = document.querySelectorAll('#subtitle')
        const line: HTMLElement | null = document.getElementById('line')
        if (!elements || !line) return
        const offsets = Array.from(elements).map((element) => getNodeCoordinates(element))
        const lineOffsets = getNodeCoordinates(line)
        setCoordinatesNode(offsets)
        window.addEventListener('scroll', () => scroll(offsets, lineOffsets))
        return () => {
            window.removeEventListener('scroll', () => scroll)
        }
    }, [document.documentElement.offsetHeight])
    const scroll = (offsets: number[], lineOffsets: any) => {
        const scrollY = document.documentElement.scrollTop
        if (scrollY >= lineOffsets && scrollY <= offsets[2]) {
            setHeight(Math.floor((scrollY - lineOffsets) * 1.2))
            return
        }
        if (scrollY < offsets[0]) {
            setHeight(0)
            return
        }
        setHeight(breakpoint ? 250 : 400)
    }

    const changePage = () => {
        dispatch(changeLink('О нас'))
    }

    return (
        <ContainerLayouts>
            <FadeLayouts fade='aboutUs'>
                <Box component='div' id='aboutUs' display='flex' gap={!breakpointSM ? '100px' : '20px'} flexWrap='wrap'
                     justifyContent='center' mb={!breakpoint ? '100px' : '10px'}>
                    <Box component='div' position='relative' display='flex' flexDirection='column' alignItems='flex-end'
                         justifyContent='flex-start' pt='12px' mr='10px'>
                        <Box component='div'>
                            <Typography fontWeight={600} fontSize='1rem' color='text.primary'>О нас</Typography>
                        </Box>
                        <Box component='div' mb='10px'>
                            <Typography className='title text-shadow' id='title' lineHeight='1.1' fontWeight={600}
                                        fontSize='2.75rem'
                                        textAlign='right' color='text.secondary'>Кто мы такие</Typography>
                        </Box>
                        <Box component='div' maxWidth='600px'>
                            <Box component='div' mb='20px'>
                                <Typography className='subtitle' textAlign='right'>
                                    Мы молодой развивающийся проект, который помогает своим клиентам в
                                    разработке высокотехнологичных решений для бизнеса, опираясь в первую очередь на
                                    качество и результат продукта.
                                </Typography>
                            </Box>
                            <Box component='div' display='flex' justifyContent='flex-end' alignItems='center'>
                                <Link to='/AboutUs'>
                                    <Paper elevation={6}>
                                        <Button onClick={changePage}
                                                endIcon={<ArrowForwardIosIcon/>} variant='outlined'>
                                            Узнать больше
                                        </Button>
                                    </Paper>
                                </Link>
                            </Box>
                            <Box component='div' className='line__wrapper'>
                                <Box component='div' id='line' className='about__line' maxHeight={breakpoint ? '250px' : '400px'}
                                     height={height + 'px'}>

                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box component='div' display='flex' gap={!breakpoint ? '30px' : '10px'} flexDirection='column'>
                        {aboutUsInfo.map((element) => (
                            <Box component='div' key={element.title} display='flex' alignItems='center' gap='10px' flexWrap='wrap'>
                                <Box component='div' display='flex' flexDirection='column' maxWidth='100px' width='100%'
                                     mr='40px'>
                                    <Typography className='title' fontSize='2.75rem'
                                                fontWeight={700}>{element.quantity}</Typography>
                                    <Typography id={element.id} className='subtitle'
                                                color={window.scrollY > coordinatesNode[element.elevation] ? 'primary.main' : 'text.secondary'}>{element.title}</Typography>
                                </Box>
                                <Box component='div' className='about__block__text'>
                                    <Typography className='subtitle'>{element.about}</Typography>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </FadeLayouts>
        </ContainerLayouts>
    );
};

export default AboutUs;