import React from 'react';
import ContainerLayouts from "../../../../layouts/ContainerLayouts";
import {
    Box, Typography
} from "@mui/material";
import Form from '../../../../rootBlocks/form/Form'
import ContactsCompany from '../../../../rootBlocks/contactsCompany/ContactsCompany'

const ContactUs = () => {


    return (
        <Box component='div' className='contact_us'>
            <ContainerLayouts>
                <Box component='div' p='20px 0' width='100%' display='flex' flexWrap='wrap' justifyContent='space-between' gap='10px'>
                    <Box component='div' display='flex' gap='10px' flexDirection='column'>
                        <Typography className='title text-shadow' textAlign='right' variant='h2' color='primary.main'>Наши
                            контакты</Typography>
                        <ContactsCompany color='white'/>
                    </Box>
                    <Box component='div'>
                        <Form titleColor='primary.main' subtitleColor='white' textFieldBackground='rgb(22, 28, 36, 0.5)'
                              textFieldVariant='filled' square/>
                    </Box>
                </Box>
            </ContainerLayouts>
        </Box>
    );
};

export default ContactUs;
