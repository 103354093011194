import React from 'react';
import {Box, Typography, Paper, useMediaQuery} from "@mui/material";
import ProjectMobile from './ProjectMobile'
import ContainerLayouts from "../../../../layouts/ContainerLayouts";
import {IProject, IProjectDesktop} from "./portfolio.interfaces";
import {Link} from 'react-router-dom'
import {
    KomandirovkaImg1,
    TrackerImg,
    AsistImg,
    Gastronorma1,
    Gastronorma2,
    Gastronorma3,
    Gastronorma4,
    Slog1,
    Slog2,
    Slog3,
    Slog4,
    Slog5,
    SopSop1,
    SopSop2,
    SopSop3,
    SopSop4,
    SopSop5,
    Calendar1,
    Calendar2,
    Calendar3,
    Calendar4,
    Calendar5
} from './index'
import {theme} from "../../../../theme/theme";

const projectsDesctop: IProjectDesktop[] = [
    {
        title: "KOMANDIROVKA.LIFE",
        img: KomandirovkaImg1,
        description: "Приложение, созданное для того, чтобы люди в командировке могли познакомиться с другими командировочными, находящимися в одном городе. В приложении пользователь может найти человека для совместного времяпрепровождения по интересам, возрасту и полу и начать с ним общение через чат, оплатив подписку.",
        dateAt: 2022
    },
    {
        title: "Work Tracker",
        img: TrackerImg,
        description: "Cистема учета рабочего времени сотрудников, разработанная для внедрения в компанию одного из наших клиентов. В системе реализован функционал Google календаря с расширенными аналитическими возможностями и возможностью разделять исполнителей задач и мероприятий по отдельным проектам, и группам должностей и обязанностей. Проект включает в себя административную панель настройки конкретной компании, в которой можно выставить роли администраторов, контролеров и исполнителей задач.",
        dateAt: 2022
    },
    {
        title: "ASIST",
        img: AsistImg,
        description: "Удобный интерфейс для поиска аналогов по заранее составленной смете. Приложение позволяет загрузить в него файл со списком необходимого оборудования по заранее скачанному шаблону и получить в ответ заполненный файл с аналогами.",
        dateAt: 2022
    },
]
const projectsMobile: IProject[] = [
    {
        title: "Gastronorma",
        img: [Gastronorma1, Gastronorma2, Gastronorma3, Gastronorma4],
        description: "Мобильное приложение для ресторанов и кафе. Подойдет как для больших сетей, так и для маленьких заведений. В приложении реализованы функции доставки и самовывоза, а также заказа на месте. Приложение Gastronorma - современная замена бумажным блокнотам с интегрированной системой лояльности.",
        dateAt: 2022
    },
    {
        title: "Sop Sop",
        img: [SopSop1, SopSop2, SopSop3, SopSop4, SopSop5],
        description: "Мобильное приложение для beauty-мастеров, призванное упростить ведение клиентской записи и привлекать больше новых клиентов. В приложении можно выстроить удобный график, вести клиентскую базу и следить за записями на услуги в удобном таймлайне.",
        dateAt: 2022
    },
    {
        title: "NaSutkiCalendar",
        img: [Calendar1, Calendar2, Calendar3, Calendar4, Calendar5],
        description: "Мобильное приложение для риэлторов и людей сдающих квартиры посуточно, в котором реализован функционал управления заселением постояльцев, просмотра черного списка клиентов, ведения нескольких квартир, звонков клиенту из приложения, а также просмотр доходов за  каждый месяц.",
        dateAt: 2021
    },
    {
        title: "Slog",
        img: [Slog1, Slog2, Slog3, Slog4, Slog5],
        description: "Мобильные приложения для людей, которые хотят уменьшить свой экослед, сдавая упаковку и другое вторсырье на переработку. Приложения разработаны в сотрудничестве с компаниями по переработке отходов.",
        dateAt: 2022
    },
]

const Portfolio = () => {
    const breakpoint = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <ContainerLayouts>
            <Box component='div' display='flex' flexDirection='column' width='100%'>
                <Box component='div' mb={!breakpoint ? '50px' : '10px'}>
                    <Typography
                        id='title'
                        className='title text-shadow'
                        lineHeight='1.1' fontWeight={600} fontSize='2.75rem'
                        textAlign='center'
                    >
                        Наши работы
                    </Typography>
                </Box>

                <Box component='div' display='flex' flexWrap="wrap" gap={5} justifyContent='center'
                     mb={!breakpoint ? '50px' : '10px'}>
                    <Box component='div' display='flex' width='100%' justifyContent="center" flexWrap="wrap" gap='1rem'>
                        {projectsMobile.map(item => (
                            <Box component='div' key={item.title} display='flex' flexDirection='column' gap='10px'>
                                <ProjectMobile item={item}/>
                            </Box>
                        ))}
                    </Box>
                    <Box component='div' display='flex' flexDirection='column' gap='1rem'>
                        {projectsDesctop.map((item, index) => (
                                <Paper key={index} elevation={9}>
                                    <Link to={`/portfolio/:${item.title}`} state={item}>
                                        <Box component='div' className='project_card__desc'>
                                            <Box component='div' padding={!breakpoint ? '0 20px' : '10px 20px'}
                                                 maxWidth={!breakpoint ? '60%' : 'max-content'}
                                                 display='flex' flexDirection='column' gap='10px' justifyContent="center">
                                                <Typography
                                                    className='subtitle'
                                                    fontWeight={600}
                                                    fontSize='1rem'
                                                    color='text.secondary'>
                                                    {item.dateAt}
                                                </Typography>
                                                <Typography
                                                    className='title'
                                                    fontWeight={600}
                                                    fontSize='2rem'
                                                    color='text.secondary'>
                                                    {item.title}
                                                </Typography>
                                                <Typography
                                                    className='subtitle'>
                                                    {item.description}
                                                </Typography>
                                            </Box>
                                            <Box component='div' className='project_card__desc__img'>
                                                <img src={item.img} alt='Изображение'/>
                                            </Box>
                                        </Box>
                                    </Link>
                                </Paper>
                            )
                        )}
                    </Box>
                </Box>
            </Box>
        </ContainerLayouts>
    )
}

export default Portfolio;
