import React from "react";
import {
    AboutUs,
    Introduction,
    Portfolio,
    OurTeams,
    ContactUs,
    Main,
    Stack,
    Testimonials
} from "./blocks/index"
import {Footer} from "../../rootBlocks";

const StartPage = () => {

    return (
        <>
            <Main/>
            <AboutUs/>
            <Introduction/>
            <Testimonials/>
            <Stack/>
            <Portfolio/>
            <OurTeams/>
            <ContactUs/>
            <Footer/>
        </>

    );
}

export default StartPage