import {Box, Typography} from '@mui/material';
import React from 'react';
import FadeLayouts from "../../../../layouts/FadeLayouts";
import {Anya, Artem, Danya, Kolya, Misha, Nikita, Victor, Vlad,Roma, Maksim} from './assets/index'


interface ITeam {
    image: string,
    name: string,
    position: string

}

const teamMobile: ITeam[] = [
    {image: Anya, name: 'Анна Ефремова', position: 'Product manager'},
    {image: Artem, name: 'Артем Сальников', position: 'Fullstack разработчик'},
    {image: Roma, name: 'Роман Зюзько', position: 'Android разработчик'},
    {image: Maksim, name: 'Максим Гузеев', position: 'iOS разработчик'},
    {image: Danya, name: 'Даниил Яковлев', position: 'Frontend разработчик'},
    {image: Kolya, name: 'Николай Дьячков', position: 'Fullstack разработчик'},
    {image: Misha, name: 'Михаил Третьяков', position: 'Frontend разработчик'},
    {image: Nikita, name: 'Никита Гущин', position: 'Android разработчик'},
    {image: Victor, name: 'Виктор Иванов', position: 'Генеральный директор'},
    {image: Vlad, name: 'Влад Пермяков', position: 'iOS разработчик'},
]

const OurTeams = () => {

    return (
        <FadeLayouts fade='team'>
            <Box component='div' id='team' className='team__background' m='50px 0'>
                <Box component='div' className='team__container' display='flex' justifyContent='center' flexWrap='wrap'
                     alignItems='center' gap='50px'>
                    <Box component='div' display='flex' flex='1' justifyContent='flex-start' flexDirection='column'
                         alignItems='center'>
                        <Box component='div' mb='10px'>
                            <Typography id='title' className='title text-shadow' textAlign='center'
                                        lineHeight='1.1' fontWeight={600}
                                        fontSize='2.75rem'
                                        color='text.secondary'>Наша команда</Typography>
                        </Box>
                        <Box component='div' justifyContent='flex-end' maxWidth='500px' p='0 20px'>
                            <Typography className='subtitle' textAlign='center'
                                        lineHeight='1.5' fontWeight={600}
                                        fontSize='0.9rem'
                                        color='text.primary'>
                                Наша команда — главная ценность компании, а основная наша цель — успех наших
                                заказчиков и сотрудников.
                                Мы уделяем много внимания профессиональному и личностному росту наших
                                специалистов.</Typography>
                        </Box>
                    </Box>
                    <Box component='div' className='our__team' flex='1' display='flex' justifyContent='center' alignItems='center'
                         position='relative' p='15px 30px' gap='10px'>
                        {teamMobile.map((element, imagesIndex) => (
                            <Box component='div' key={imagesIndex}
                                 borderRadius='5px' flexShrink='0'>
                                <Box component='div' display='flex' flexDirection='column' alignItems='flex-start' maxWidth='150px'
                                     justifyContent='flex-start'>
                                    <img className='our__team__img' src={element.image} alt="Изображение"/>
                                    <Box component='div' mt='10px'>
                                        <Typography className='subtitle' fontSize='0.8rem' color='text.secondary'>
                                            {element.name}
                                        </Typography>
                                        <Typography className='subtitle' fontSize='0.7rem' color='primary.main'>
                                            {element.position}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
        </FadeLayouts>
    );
};

export default OurTeams;
