import React from 'react';
import {Box, Fade, Paper, Popper,Typography} from "@mui/material";
import {IAgeButton, IPopper} from "./ageButton.interfaces";

const AgeButton: React.FC<IAgeButton> = ({popperText, icon, changeActive}) => {
    const [popper, setPopper] = React.useState<IPopper>({
        open: false,
        anchorEl: null
    })

    const handlePopper = (event: React.MouseEvent<HTMLElement>) => {
        setPopper({...popper, anchorEl: event.currentTarget, open: true})
    }

    const leavePopper = () => {
        setPopper({...popper, open: false})
    }

    const canBeOpen = popper.open && Boolean(popper.anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;

    return (
        <Paper className='age__settings age__settings--active' elevation={9}>
            <Box component='div' onClick={()=>changeActive(popperText)} onMouseEnter={(e) => handlePopper(e)} onMouseLeave={leavePopper} width='25px' height='25px'
                 display='flex' justifyContent='center' alignItems='center'>
                {icon}
            </Box>
            <Popper id={id} open={popper.open} anchorEl={popper.anchorEl}
                    transition>
                {({TransitionProps}) => (
                    <Fade {...TransitionProps}>
                        <Box component='div' mt='10px' p='5px' bgcolor='rgba(55, 65, 81, 0.28)' boxShadow='0 5px 12px rgba(0, 0, 0, 0.15)' borderRadius='6px'>
                            <Typography color='white'>
                                {popperText}
                            </Typography>
                        </Box>
                    </Fade>
                )}
            </Popper>
        </Paper>
    );
};

export default AgeButton;