import React from 'react';
import {Box, Paper, Typography, Fade} from "@mui/material";
import {IProjectMobile} from "./portfolio.interfaces";
import CarouselImage from "../../../../rootBlocks/carouselImages/CarouselImage";
import {Link} from "react-router-dom";


const settings = {
    swipeable: true,
    infiniteLoop: true,
    autoPlay: true,
    showThumbs: false,
    emulateTouch: true,
    showArrows: false
}
const ProjectMobile: React.FC<IProjectMobile> = ({item}) => {
    const [show, setShow] = React.useState<boolean>(false)
    const onMouseEnter = () => {
        setShow(true)
    }
    const onMouseLeave = () => {
        setShow(false)
    }


    return (
        <Box component='div' className='project__mobile' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <Paper elevation={9}>
                <Box component='div' position='relative' display='flex' justifyContent='center'
                     flexDirection='column' key={item.title}>
                    <Box component='div' p='20px'>
                        <Typography className='title' color='text.secondary' fontSize='1.6rem' fontWeight={600}
                                    lineHeight='1.5'
                                    textAlign='center'>{item.title}</Typography>
                    </Box>
                    <CarouselImage maxWidth='300px' element={item.img} settings={settings}/>
                    <Fade in={show} timeout={300} unmountOnExit>
                        <Link to={`/portfolio/:${item.title}`} state={item}>
                            <Box component='div' className='project__description'>
                                <Box component='div'>
                                    <Typography color='text.secondary' fontSize='0.9rem' fontWeight={600}
                                                lineHeight='1.5'
                                                textAlign='left'>{item.description}</Typography>
                                </Box>
                            </Box>
                        </Link>
                    </Fade>
                </Box>
            </Paper>
        </Box>
    );
};

export default ProjectMobile;