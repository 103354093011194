import React from 'react';
import {Box, Typography, Snackbar, Button, useMediaQuery} from '@mui/material';
import {useLocation} from "react-router-dom";
import ContainerLayouts from "../../layouts/ContainerLayouts";
import FadeLayouts from "../../layouts/FadeLayouts";
import CarouselImage from "../../rootBlocks/carouselImages/CarouselImage";
import UserDataForm from "../../rootBlocks/form/UserDataForm";
import {useFormik} from "formik";
import * as Yup from "yup";
import {createOrder} from "../../HTTP/controllers/orderController";
import FormButtonSubmit from "../../rootBlocks/form/FormButtonSubmit";
import MuiAlert from "@mui/material/Alert";
import {useSnackbar} from "../../hooks/useSnackbar";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {Link} from 'react-router-dom'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {theme} from "../../theme/theme";

const settings = {
    swipeable: true,
    infiniteLoop: true,
    autoPlay: false,
    showThumbs: true,
    emulateTouch: true,
    showArrows: true,
    renderArrowPrev: (clickHandler: () => void) => (
        <Box component='div' className='button__render--left' >
            <Button
                size='small' onClick={clickHandler}
                variant='outlined'><KeyboardArrowLeftIcon
                fontSize='small'/>
            </Button>
        </Box>),
    renderArrowNext: (clickHandler: () => void) => (
        <Box component='div' className='button__render--right'>
            <Button
                size='small' onClick={clickHandler}
                variant='outlined'><KeyboardArrowRightIcon
                fontSize='small'/>
            </Button>
        </Box>),
}
const CurrentWork = () => {
    const breakpointSM = useMediaQuery(theme.breakpoints.down('sm'))
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const {closeSnackbar, openSnackbar, snackbar} = useSnackbar()
    const {state} = useLocation();

    const formik = useFormik({
        initialValues: {
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
        },
        validationSchema: Yup.object({
            email: Yup
                .string()
                .email('')
                .max(50)
                .required('Не является почтой'),
            firstName: Yup
                .string()
                .max(15)
                .required('Данное поле обязательно'),
            lastName: Yup
                .string()
                .max(50)
                .required('Данное поле обязательно'),
        }),

        onSubmit: () => {
            const {email, lastName, firstName, phone} = formik.values;
            (async () => {
                setIsLoading(true)
                try {
                    const response = await createOrder({
                        email,
                        name: `${firstName} ${lastName}`,
                        phone,
                    })
                    if (response.status === 201) {
                        openSnackbar({message: 'Ваша заявка была успешно создана', severity: "success"})
                    }
                } catch (e) {
                    openSnackbar({message: 'Что-то пошло не так', severity: "error"})
                } finally {
                    setIsLoading(false)
                }
            })()
        }
    });
    return (
        <ContainerLayouts>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={4000}
                onClose={closeSnackbar}
            >
                <MuiAlert onClose={closeSnackbar} variant='filled' elevation={9}
                          sx={{width: '100%', backgroundColor: snackbar.severity, color: 'white'}}>
                    {snackbar.message}
                </MuiAlert>
            </Snackbar>
            <FadeLayouts fade='currentWork'>
                <Box component='div' id='currentWork' width='100%' mt='100px'>
                    <Box component='div' mb='20px'>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link to='/portfolio'>
                                <Typography color="text.primary" className='breadcrumbs'>
                                    Портфолио
                                </Typography>
                            </Link>
                            <Typography variant='subtitle2' color='text.secondary'>{state.title}</Typography>
                        </Breadcrumbs>
                        <Box component='div' justifyContent={breakpointSM ? 'center': 'flex-start'} display='flex' flexWrap='wrap' gap='30px' alignItems={breakpointSM ? 'center':'flex-start'}>
                            <CarouselImage className='carousel__portfolio carousel__portfolio--media' settings={settings} maxWidth={!breakpointSM ? '510px':'300px'}
                                           element={state.img}/>

                            <Box component='div' display='flex' flexDirection='column' gap='10px' maxWidth='600px'>
                                <Typography className='title text-shadow' textAlign='left'
                                            lineHeight='1.1' fontWeight={600}
                                            fontSize='2.75rem'
                                            color='text.secondary'>{state.title}</Typography>
                                <Box component='div' mb='10px'>
                                    <Typography variant='subtitle2' className='subtitle'>
                                        {state.description}
                                    </Typography>
                                </Box>

                                <Box component='div' display='flex' flexDirection='column' gap='10px'>
                                    <Box component='div' display='flex' gap='5px' flexDirection='column'>
                                        <Typography className='title' textAlign='left'
                                                    lineHeight='1.1' fontWeight={600}
                                                    fontSize='1.5rem'
                                                    color='text.secondary'>
                                            Имеются вопросы или предложения?
                                        </Typography>
                                        <Typography variant='subtitle2' className='subtitle'>
                                            Оставьте ваши контакты и мы обязательно свяжемся с Вами!
                                        </Typography>
                                    </Box>
                                    <form onSubmit={formik.handleSubmit}>
                                        <Box component='div'>
                                            <UserDataForm formik={formik}/>
                                            <FormButtonSubmit isSubmitting={formik.isSubmitting} isLoading={isLoading}
                                                              buttonTitle='Отправить'/>
                                        </Box>
                                    </form>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </FadeLayouts>
        </ContainerLayouts>

    );
};

export default CurrentWork;