import React from 'react';
import {useMediaQuery} from "@mui/material";
import {theme} from '../theme/theme'

/**Хук позволяеющий наблюдать за тем, отображается ли элемент на экране пользователя
 * @param id - идентификационный номер html elemenet'a
 * */
const useObserver = (id: string) => {
    const [view, setView] = React.useState<boolean>(false)
    const breakpoint = useMediaQuery(theme.breakpoints.down('sm'))

    const options = {
        rootMargin: '0px',
        threshold: !breakpoint ? 0.2 : 0.1
    };
        const changeState = (entries: any) => {
            if (entries[0].isIntersecting) {
                setView(true)
            }
        }

    React.useEffect(() => {
        const observer = new IntersectionObserver(changeState, options);
        const target = document.getElementById(id);
        observer.observe(target as Element); // запускаем "слежку" за элементом в константе target
    }, [])

    return {view,setView}
};

export default useObserver;