import {IProject} from "../projectCard/projectCard.interfaces";
import {IAgeInput, ICheckedProjects} from "../projects/project.interfaces";
import {mock, mock2} from "../projects/Projects";

const checkFindInput = (element: IProject, findInput: string) => {
    return element.title.toLowerCase().trim().includes(findInput.trim().toLowerCase())
}
const checkAgeInput = (element: IProject, {after, before, date}: IAgeInput) => {
    const dateAfter = after ? Number(after) : 0
    const dateBefore = before ? Number(before) : 9999

    const dateAfterInterval = dateAfter <= element.dateAt
    const dateBeforeInterval = dateBefore >= element.dateAt

    if (dateAfter !== 0 || dateBefore !== 9999) {
        return dateAfterInterval && dateBeforeInterval
    } else if (dateAfter === 0 && dateBefore === 9999) {
        return element.dateAt.toString().includes(date)
    }
}

const checkProjects = (checkedProjects: ICheckedProjects) => {
    let allProject: IProject[] = []
    if (!checkedProjects.desktop && !checkedProjects.mobile) return allProject
    if (checkedProjects.mobile && checkedProjects.desktop) {
        allProject = [...mock2, ...mock]
    } else if (checkedProjects.desktop) {
        allProject = mock2

    } else if (checkedProjects.mobile) {
        allProject = mock
    }
    return allProject
}

export {checkProjects, checkFindInput, checkAgeInput}