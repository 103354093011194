import React from 'react';
import ContainerLayouts from '../../../../layouts/ContainerLayouts';
import FadeLayouts from '../../../../layouts/FadeLayouts';
import {Box, Typography, Button, } from "@mui/material";
import {ITestimonials} from "./testimonials.interfaces";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import GRR from './assets/GRR.svg'

const mockTestimonials: ITestimonials[] = [
    {
        name: 'IEK GROUP',
        text: 'Российская электротехническая компания, производитель и поставщик электротехнического оборудования под брендом IEK.',
        icon: 'https://www.iek.ru/local/assets/img/logo.svg'
    },
    {
        name: 'Iron Logic',
        text: 'Производитель оборудование для систем контроля доступа.',
        icon: 'https://ironlogic.ru/il_new.nsf/img/ru_logo.png/$FILE/logo.png'
    },
    {
        name: 'SCRAP',
        text: 'Флагман российского бизнеса в сфере оптовых поставок товаров народного потребления.',
        icon: 'https://skrap.ru/upload/medialibrary/07f/07f9c2f0a159cba38dfc121374a9d062.png'
    },
    {
        name: 'ВКМТ',
        text: 'Крупный поставщик диагностического медицинского оборудования – GE Healthcare, Philips, Storz, МТЛ и еще более чем 100 производителей.',
        icon: 'https://vkmt.ru/wp-content/uploads/2021/10/cropped-logo.png'
    },
    {
        name: 'ГРРЦ',
        text: 'Компания по переработке стекла в сырье для вторичного использования.',
        icon: GRR
    },
    {
        name: 'Vendex',
        text: 'Производитель автоматических кафе-аппаратов.',
        icon: 'https://vendex.ru/wp-content/uploads/2021/11/cropped-logo-vendex-removebg-preview-2.png'
    },
]


const settings = {
    swipeable: true,
    infiniteLoop: true,
    interval: 3000,
    autoPlay: false,
    showThumbs: false,
    emulateTouch: true,
    showArrows: false,
    renderArrowPrev: (clickHandler: () => void) => (
        <Button
            variant='text'
            className='arrow_prev'
            onClick={clickHandler}>
            <ArrowBackIosNewIcon onClick={clickHandler}/>
        </Button>),
    renderArrowNext: (clickHandler: () => void) => (
        <Button
            variant='text'

            className='arrow_next'
            onClick={clickHandler}>
            <ArrowForwardIosIcon onClick={clickHandler}/>
        </Button>)
};

/**
 * Настройки слайдера
 * swipeable - позволяет пользователю свайпать слайдер
 * infiniteLoop - бесконечный слайд
 * interval - интервал изменения слайда
 * autoPlay - автоматическое изменение слайда
 * showThumbs - показывать точки
 * emulateTouch - позволяет пользователю свайпать слайдер с помощью мыши
 * узнать больше https://github.com/leandrowd/react-responsive-carousel
 * */
const Testimonials = () => {
    return (
        <Box component='div' bgcolor='#F9FAFB'>
            <ContainerLayouts>
                <FadeLayouts fade='testimonials'>
                    <Box component='div' id='testimonials'>
                        <Box component='div' display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                            <Typography id='title' fontWeight={600} fontSize='1rem'
                                        color='text.primary'>Отзывы</Typography>
                            <Typography className='title text-shadow' lineHeight='1.1' fontWeight={600} fontSize='2.75rem'
                                        textAlign='center'>Кому понравилась наша
                                работа</Typography>
                        </Box>
                        <Box component='div' maxWidth='800px' margin='auto'>
                            <Carousel {...settings}>
                                {mockTestimonials.map((element) => (
                                    <Box component='div' key={element.name} display='flex' gap='30px' justifyContent='center'
                                         alignItems='center'
                                         flexDirection='row'
                                         height='100%'
                                         mb='50px'>
                                        <Box component='div' maxWidth='500px' display='flex' justifyContent='center' alignItems='center'
                                             textAlign='left' height='100%'>
                                            <Typography className='subtitle'>
                                                {element.text}
                                            </Typography>
                                        </Box>
                                        <Box component='div' display='flex' flexDirection='column' alignItems='center'
                                             justifyContent='center'>
                                            <Box component='div' mb='10px' maxWidth='150px'>
                                                <img src={element.icon} alt='Иконка'/>
                                            </Box>
                                            <Typography fontSize='1rem' fontWeight={600}>{element.name}</Typography>
                                        </Box>
                                    </Box>
                                ))}
                            </Carousel>
                        </Box>
                    </Box>
                </FadeLayouts>
            </ContainerLayouts>
        </Box>
    );
};

export default Testimonials;
