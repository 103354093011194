import React from 'react';
import {Box, Paper, TextField, useMediaQuery} from "@mui/material";
import {styled} from "@mui/material/styles";
import {IMaskInput} from "react-imask";
import {IUserDataForm} from "./form.interfaces";
import {theme} from "../../theme/theme";

export const CustomTextField = styled(TextField)(({theme}) => ({
    transition: 'all .1s linear',
    color: theme.palette.text.primary,
    '.MuiFormLabel-root': {
        color: theme.palette.text.primary,
    },
    '.MuiInputBase-root': {
        color: theme.palette.text.primary,
    },
    '.MuiOutlinedInput-notchedOutline': {
        border: 'none'
    },
}))

const TextMaskCustom = React.forwardRef<HTMLElement, any>(
    function TextMaskCustom(props, ref) {
        const {onChange, ...other} = props;
        return (
            <IMaskInput
                {...other}
                mask="+#(000) 000-0000"
                definitions={{
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value: any) => onChange({target: {name: props.name, value}})}
            />
        );
    },
);

const UserDataForm: React.FC<IUserDataForm> = ({
                                                   formik,
                                                   textFieldVariant = 'outlined',
                                                   textFieldBackground,
                                                   square
                                               }) => {
    const breakpointSM = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <Box component='div'>
            <Box component='div' display='flex' gap='10px' mb='10px'>
                <Box component='div' width='50%' zIndex='1' position='relative'>
                    <Paper elevation={9} sx={{
                        backgroundColor: textFieldBackground,
                        borderTopRightRadius: '8px',
                        borderTopLeftRadius: '8px'
                    }} square={square}>
                        <CustomTextField
                            error={Boolean(formik.touched.firstName && formik.errors.firstName)}
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            fullWidth
                            label='Имя'
                            name='firstName'
                            type='text'
                            variant={textFieldVariant}
                            color='primary'
                            size={breakpointSM ? 'small' : 'medium'}/>
                    </Paper>
                </Box>
                <Box component='div' width='50%' zIndex='1' position='relative'>
                    <Paper elevation={9} sx={{
                        backgroundColor: textFieldBackground,
                        borderTopRightRadius: '8px',
                        borderTopLeftRadius: '8px'
                    }} square={square}>
                        <CustomTextField
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.lastName && formik.errors.lastName)}
                            fullWidth
                            label='Фамилия'
                            name='lastName'
                            type='text'
                            variant={textFieldVariant}
                            color='primary'
                            size={breakpointSM ? 'small' : 'medium'}/>
                    </Paper>
                </Box>
            </Box>
            <Box component='div' mb='10px' zIndex='1' position='relative'>
                <Paper elevation={9} sx={{
                    backgroundColor: textFieldBackground,
                    borderTopRightRadius: '8px',
                    borderTopLeftRadius: '8px'
                }} square={square}>
                    <CustomTextField
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.email && formik.errors.email)}
                        fullWidth
                        label='Почта'
                        name='email'
                        type='text'
                        variant={textFieldVariant}
                        color='primary'
                        size={breakpointSM ? 'small' : 'medium'}/>
                </Paper>
            </Box>
            <Box component='div' mb='10px' zIndex='1' position='relative'>
                <Paper elevation={9} sx={{
                    backgroundColor: textFieldBackground,
                    borderTopRightRadius: '8px',
                    borderTopLeftRadius: '8px'
                }} square={square}>
                    <CustomTextField
                        fullWidth
                        value={formik.values.phone}
                        error={Boolean(formik.touched.phone && formik.errors.phone)}
                        onChange={(e) => formik.setFieldValue('phone', e.target.value)}
                        label='Телефон'
                        type='text'
                        InputProps={{
                            inputComponent: TextMaskCustom as any
                        }}
                        variant={textFieldVariant}
                        color='primary'
                        size={breakpointSM ? 'small' : 'medium'}
                    />
                </Paper>
            </Box>
        </Box>
    )
        ;
};

export default UserDataForm;
