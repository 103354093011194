import React from 'react';
import {Box, Typography} from "@mui/material";
import CurrentService from "./CurrentService";

const listServices = [
    {
        title: 'Разработка на заказ',
        subtitle: 'Решим задачи вашего бизнеса — разработаем программное обеспечение в срок и в рамках бюджета.',
        href: '/AboutUs/development'
    },
    {
        title: 'Что мы предлагаем',
        subtitle: 'Мы предлагаем индивидуальный подход к каждому проекту, которое полностью соответствует потребностям наших клиентов.',
        href: '/AboutUs/services'
    },
    {
        title: 'Администраторы',
        subtitle: 'Наши администраторы заботятся о том, чтобы все наши системы работали без сбоев и проблем.',
        href: '/AboutUs/administrators'
    }
]

const Services = () => {
    return (
        <Box component='div' id='services' width='100%' mt='100px'>
            <Box component='div' mb='10px'>
                <Typography className='title text-shadow' textAlign='center' variant='h2'>
                    Услуги
                </Typography>
            </Box>
            <Box component='div' width='100%' className='background__services'>
                <Box component='div' display='flex' gap='20px' justifyContent='center' width='100%' flexWrap='wrap'>
                    {listServices.map((element) => (
                        <CurrentService key={element.title} element={element}/>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default Services;