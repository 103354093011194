import React from 'react'
import {
    Box,
    Typography, useMediaQuery, IconButton, Button
} from '@mui/material'
import {styled} from "@mui/material/styles";
import ContainerLayouts from "../../layouts/ContainerLayouts";
import MenuIcon from '@mui/icons-material/Menu';
import Panel from "../Panel/Panel";
import {INavigationTitle} from "./header.interfaces";
import {NavLink as Link} from 'react-router-dom'
import {changeLink} from '../../store/reducers/headerReducer/headerReducer'
import {useAppDispatch} from "../../hooks/useTypeSelector";

const navigationTitles: INavigationTitle[] = [
    {title: 'Главная', link: '/'},
    {title: 'О нас', link: '/AboutUs'},
    {title: 'Внедрение Bitrix24', link: 'https://b24.itpw.ru/', isExternal: true},
    {title: 'Портфолио', link: '/Portfolio'},
    {title: 'Контакты', link: '/Contacts'},
]
const CustomButton = styled(Button)(({theme}) => ({
    transition: 'all .1s linear',
    textTransform: 'capitalize',
    color: theme.palette.text.primary,
    '&:hover': {
        color: theme.palette.primary.main,
        transition: 'all .1s linear',
    },
}))

const Header: React.FC = () => {
    const dispatch = useAppDispatch()
    const breakpoint = useMediaQuery('(max-width:1024px)');
    const [isScrollable, setIsScrollable] = React.useState<boolean>(false)
    const [panel, setPanel] = React.useState<boolean>(false)

    React.useEffect(() => {
        window.addEventListener('scroll', scroll)
        return () => {
            window.removeEventListener('scroll', scroll)
        }
    }, [])
    const scroll = () => {
        const scroll = window.scrollY
        if (scroll > 10) {
            setIsScrollable(true)
            return
        }
        setIsScrollable(false)

    }
    const closePanel = () => {
        setPanel(false)
        document.body.style.overflow = 'auto';

    }
    const openPanel = () => {
        setPanel(true)
        document.body.style.overflow = 'hidden';
    }
    const changeTitle = (title: string) => {
        dispatch(changeLink(title))
    }

    return (
        <Box component='div' className={isScrollable ? 'header header--active' : 'header'}>
            <ContainerLayouts>
                <Box component='div' width='100%' height='100%' display='flex' alignItems='center'
                     justifyContent={!breakpoint ? 'flex-start' : 'space-between'}>
                    <Box component='div' display='flex' alignItems='center' mr='80px'>
                        <Typography color='primary.main' fontSize='1.2rem' fontWeight={600}>
                            IT
                        </Typography>
                        <Typography color='text.secondary' fontSize='1.2rem' fontWeight={600}>
                            -POWER
                        </Typography>
                    </Box>
                    {!breakpoint ? (
                        <Box component='div' display='flex' gap='100px' alignItems='center'>
                            <Box component='div' display='flex' gap='20px' alignItems='center'>
                                <Box component='div' display='flex' gap='10px'>
                                    {navigationTitles.map((element) => (
                                        <>
                                            {element?.isExternal
                                                ? (
                                                    <a key={element.title} href={element.link} target={'_blank'}
                                                       >
                                                        <CustomButton className={'navigation__link'}>
                                                        {element.title}
                                                        </CustomButton>
                                                    </a>
                                                ) : (
                                                    <Link key={element.title} to={element.link}>
                                                        {({isActive}) => (
                                                            <CustomButton onClick={() => changeTitle(element.title)}
                                                                          className={isActive ? 'navigation__link navigation__link--active' : 'navigation__link'}>{element.title}</CustomButton>
                                                        )}
                                                    </Link>
                                                )}

                                        </>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    ) : (
                        <>
                            <IconButton color='primary' onClick={openPanel} sx={{padding: 0}}>
                                <MenuIcon fontSize="medium"/>
                            </IconButton>
                            <Panel closePanel={closePanel} navigationTitles={navigationTitles} isVisible={panel}/>
                        </>

                    )}
                </Box>
            </ContainerLayouts>
        </Box>
    )
}

export default Header
