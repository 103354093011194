import React from 'react';
import ContainerLayouts from "../../../../layouts/ContainerLayouts";
import FadeLayouts from "../../../../layouts/FadeLayouts";
import {Box, Typography} from "@mui/material";

const Development = () => {


    return (
        <ContainerLayouts>
            <FadeLayouts fade='why__we'>
                <Box component='div' id='why__we' width='100%' mt='100px'>
                    <Box component='div' mb='10px'>
                        <Typography className='title text-shadow' textAlign='center' variant='h2'>Почему
                            мы?
                        </Typography>
                    </Box>
                </Box>
            </FadeLayouts>
        </ContainerLayouts>
    );
};

export default Development;