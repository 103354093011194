import React from 'react';
import {Box, Typography} from "@mui/material";

import EmailIcon from "@mui/icons-material/Email";
import HomeIcon from "@mui/icons-material/Home";
import PhoneIcon from "@mui/icons-material/Phone";
import {IContactsCompany} from "./contactsCompany.interfaces";

const ContactsCompany:React.FC<IContactsCompany> = ({color}) => {
    const contactUsInfo = (color: string) => [
        {icon: <EmailIcon sx={{color: color}}/>, subtitle: 'info@itpw.ru', title: 'Почта'},
        {icon: <HomeIcon sx={{color: color}}/>, subtitle: 'ООО "АйТи-Пауэр"', title: 'Компания'},
        {icon: <PhoneIcon sx={{color: color}}/>, subtitle: '8(905) 280-06-57', title: 'Телефон'},
    ]
    return (
        <Box component='div' display='flex' flexDirection='column' gap='10px' position='relative' zIndex='1'>
            {contactUsInfo(color).map((element) => (
                <Box component='div' key={element.title} display='flex' gap='10px' flexDirection='column'>
                    <Box component='div' display='flex' gap='5px' alignItems='center'>
                        {element.icon}
                        <Typography color={color} fontSize='1rem' className='subtitle'
                                    fontWeight={400}>{element.title}</Typography>
                    </Box>
                    <Typography color={color} fontSize='0.9rem' className='subtitle'
                                fontWeight={400}>{element.subtitle}</Typography>
                </Box>
            ))}
        </Box>
    );
};

export default ContactsCompany;