import React from 'react';
import {Box} from "@mui/material";
import {Carousel} from "react-responsive-carousel";
import { ICarouselImage } from './carouselImage.interfaces';

const CarouselImage:React.FC<ICarouselImage> = ({className='', maxWidth='300px', element, settings}) => {
    return (
        <Box component='div' maxWidth={maxWidth}>
            {Array.isArray(element) ? (
                <Carousel className={className} {...settings}>
                    {element.map((item, index) => (
                        <img key={index} style={{width: '100%'}} src={item}
                             alt="Изображение"/>))}
                </Carousel>
            ) : (
                <img style={{width: '100%'}} src={element}
                     alt="Изображение"/>
            )}
        </Box>
    );
};

export default CarouselImage;