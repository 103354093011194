import {Box, Typography, Fade, Collapse, Button, Divider} from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom'
import {IPanel} from "./panel.interfaces";
import ContainerLayouts from '../../layouts/ContainerLayouts'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {NavLink as Link} from 'react-router-dom'

const Panel: React.FC<IPanel> = ({isVisible, navigationTitles, closePanel}) => {
    return ReactDOM.createPortal(
        <Collapse in={isVisible} unmountOnExit>
            <Box component='div' className='panel'>
                <ContainerLayouts>
                    <Box component='div' width='100%' display='flex' flexDirection='column' gap='10px'>
                        <Box component='div' display='flex' justifyContent='space-between'>
                            <Box component='div' display='flex' alignItems='center'>
                                <Typography className='t-shadow' color='primary.main' fontSize='1.2rem'
                                            fontWeight={600}>
                                    IT
                                </Typography>
                                <Typography className='t-shadow' color='text.secondary' fontSize='1.2rem'
                                            fontWeight={600}>
                                    POWER
                                </Typography>
                            </Box>
                            <Button endIcon={<ArrowForwardIcon/>} onClick={closePanel}>
                                Назад
                            </Button>
                        </Box>
                        <Divider/>
                        <Box component='div' display='flex' flexDirection='column'>
                            {navigationTitles.map((element) => (
                                <Link key={element.title} to={element.link}>
                                    {({isActive}) => (
                                        <Box component='div' mb='10px' ml={isActive ? '10px' : ''}
                                             position='relative' onClick={closePanel}>
                                            <Typography className={isActive ? 't-shadow' : ''}
                                                        textAlign='left' fontWeight={600} fontSize='1.2rem'
                                                        color={isActive ? 'text.secondary' : 'text.primary'}>{element.title}</Typography>
                                            <Fade in={isActive} timeout={300} unmountOnExit>
                                                <Box component='div' className='panel__title'/>
                                            </Fade>
                                        </Box>
                                    )}
                                </Link>
                            ))}
                        </Box>
                    </Box>
                </ContainerLayouts>
            </Box>
        </Collapse>, document.getElementById('panel') as HTMLElement)
}

export default Panel;