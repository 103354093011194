import React from 'react';
import {Box, Button, CircularProgress, Paper, useMediaQuery} from "@mui/material";
import {theme} from "../../theme/theme";
import {IFormButtonSubmit} from "./form.interfaces";

const FormButtonSubmit:React.FC<IFormButtonSubmit> = ({buttonTitle,isLoading, isSubmitting}) => {
    const breakpointSM = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <Box component='div' mb='30px'>
            <Paper elevation={9} sx={{maxWidth: 'max-content'}}>
                <Button sx={{minWidth: '160px'}} disabled={isLoading || isSubmitting}
                        size={breakpointSM ? 'small' : 'medium'}
                        fullWidth={breakpointSM}
                        variant='contained'
                        type='submit'>
                    {isLoading ? <CircularProgress size={24}/> : buttonTitle}
                </Button>
            </Paper>
        </Box>
    );
};

export default FormButtonSubmit;