import React from 'react';
import {Box, Paper, Typography} from "@mui/material";
import {IProjectCard} from "./projectCard.interfaces";
import CarouselImage from "../../../../rootBlocks/carouselImages/CarouselImage";
import {Link} from 'react-router-dom'

const settings = {
    swipeable: true,
    infiniteLoop: true,
    autoPlay: false,
    showThumbs: false,
    emulateTouch: true,
    showArrows: false
}


const ProjectCard: React.FC<IProjectCard> = React.memo(({element}) => {

    return (
        <Paper elevation={9}>
            <Link to={`/portfolio/:${element.title}`} state={element}>
                <Box component='div' maxWidth='310px' p='20px' height='max-content'>
                    <CarouselImage maxWidth='300px' element={element.img} settings={settings}/>
                    <Box component='div' width='100%'>
                        <Typography variant='subtitle1' color='primary.main'
                                    className='subtitle'
                                    fontSize='0.8rem'>{element.dateAt}</Typography>
                        <Typography variant='subtitle1' color='text.secondary'
                                    className='subtitle'>{element.title}</Typography>
                        <Typography variant='subtitle1' fontSize='0.8rem'
                                    className='subtitle'>{element.description}</Typography>
                    </Box>
                </Box>
            </Link>
        </Paper>
    );
})

export default ProjectCard;