import React from 'react';
import ContainerLayouts from "../../../../layouts/ContainerLayouts";
import FadeLayouts from "../../../../layouts/FadeLayouts";
import {Typography, Box, Paper, useMediaQuery} from "@mui/material";
import DjangoIcon from '../../../../icons/BackEnd/django-icon.svg'
import PostgresqlIcon from '../../../../icons/BackEnd/postgresql-icon.svg'
import FastApiIcon from '../../../../icons/BackEnd/fastapi-1.svg'
import VueIcon from '../../../../icons/FrontEnd/vue-js-icon.svg'
import ReactIcon from '../../../../icons/FrontEnd/react-js-icon.svg'
import NextIcon from '../../../../icons/FrontEnd/nextjs-icon-svgrepo-com.svg'
import KotlinIcon from '../../../../icons/Android/kotlin-programming-language-icon.svg'
import SwiftIcon from '../../../../icons/IOS/language_swift_icon_138431.svg'
import UiKitIcon from '../../../../icons/IOS/uikit-logo.svg'
import Jetpack from '../../../../icons/Android/jetpack.png'
import nodeJs from '../../../../icons/BackEnd/nodeJs.png'
import Python from '../../../../icons/BackEnd/python.png'
import {theme} from '../../../../theme/theme'
import {IStackInfo} from "./stack.interfaces";

const StackInfo: IStackInfo[] = [
    {
        images: [{
            image: DjangoIcon,
            link: 'https://www.djangoproject.com/'
        }, {
            image: nodeJs,
            link: 'https://nodejs.org/en/'
        }, {image: PostgresqlIcon, link: 'https://www.postgresql.org/'}, {
            image: FastApiIcon,
            link: 'https://fastapi.tiangolo.com/'
        }, {
            image: Python,
            link: 'https://www.python.org/'
        }],
        title: 'BackEnd',
        subtitle: 'API каждого из наших проектов используют только самые современные и продвинутые технологии и паттерны разработки серверных приложений',
    },
    {
        images: [{image: VueIcon, link: 'https://vuejs.org/'}, {
            image: ReactIcon,
            link: 'https://ru.reactjs.org/'
        }, {image: NextIcon, link: 'https://nextjs.org/'}],
        title: 'FrontEnd',
        subtitle: 'Каждому приложению мы разрабатываем фирменный стиль, учитывая особенности каждого проекта и предпочтения его заказчика',
    },
    {
        images: [ {
            image: Jetpack,
            link: 'https://developer.android.com/jetpack/compose'
        }, {image: KotlinIcon, link: 'https://kotlinlang.org/'}],
        title: 'Android',
        subtitle: 'Дизайн, UX, мягкий запуск, цикличные обновления – все должно работать слаженно и отвечать единой цели удовлетворения потребности пользователя',
    },
    {
        images: [{image: SwiftIcon, link: 'https://www.swift.org/'}, {image: UiKitIcon, link: 'https://getuikit.com/'}],
        title: 'iOS',
        subtitle: 'Apple является настоящим фаворитом в области UI/UX и мы стараемся ни на шаг не отставать от последних тенденций в области iOS разработки',
    },
]

const Stack = () => {
    const breakpoint = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <FadeLayouts fade='stack'>
            <Box component='div' id='stack'>
                <Box component='div' className='stack_line'></Box>
                <ContainerLayouts>
                    <Box component='div' width="100%" display='flex' flexWrap='wrap' justifyContent='center' alignItems='center'
                         margin='auto'
                         flexDirection='column'>
                        <Box component='div' display='flex' flexDirection='column' alignItems='center' justifyContent='center'
                             mb={!breakpoint ? '50px' : '5px'}>
                            <Typography id='title' textAlign='center' fontWeight={600} fontSize='1rem'
                                        color='#6d7786'>Разработка</Typography>
                            <Typography className='title text-shadow' lineHeight='1.1' fontWeight={600} fontSize='2.75rem'
                                        textAlign='center'>Направления
                                разработки</Typography>
                        </Box>
                        <Box component='div' display='flex' justifyContent='center' flexWrap='wrap' gap='1rem'>
                            {StackInfo.map((element) => (
                                <Paper key={element.title} elevation={9} sx={{width: '19rem'}}>
                                    <Box component='div' className='developer_block'>
                                        <Box component='div' mb='10px'>
                                            <Typography className='title' fontWeight={600} fontSize='2rem'
                                                        color='#212b36'>{element.title}</Typography>
                                        </Box>
                                        <Box component='div' mb='10px'>
                                            <Typography className='subtitle'>{element.subtitle}</Typography>
                                        </Box>
                                        <Box component='div' display='flex' gap={1}
                                             mt={!breakpoint && element.title === 'FrontEnd' ? '35px' : ''}>
                                            {element.images.map((img, imageIndex) => (
                                                <a key={imageIndex} href={img.link}>
                                                    <img className='stack_icon' alt='картинка'
                                                         src={img.image}/>
                                                </a>
                                            ))}
                                        </Box>
                                    </Box>
                                </Paper>
                            ))}
                        </Box>
                    </Box>
                </ContainerLayouts>
            </Box>
        </FadeLayouts>

    );
};

export default Stack;
