import React from 'react';
import {WhyOurAdministrators,Monitoring} from './blocks/index'

const OurAdministrators = () => {


    return (
        <>
            <WhyOurAdministrators/>
            <Monitoring/>
        </>
    )
};

export default OurAdministrators;