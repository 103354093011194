import React from 'react';
import {
    Box,
    Collapse,
    FormControlLabel,
    FormGroup,
    Paper,
    Switch,
    Typography,
    useMediaQuery
} from "@mui/material";
import AgeButton from "../ageButton/AgeButton";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import {TextMaskCustom, CustomTextField} from "../projects/Projects";
import {IAgeInput, ICheckedProjects, typeAgeTitle, typeProjects, typeTitle} from "../projects/project.interfaces";
import {checkAgeInput, checkFindInput, checkProjects} from "./filterMenu.functions";
import {IFilterMenu} from "./filterMenu.interfaces";
import {theme} from "../../../../theme/theme";

const FilterMenu: React.FC<IFilterMenu> = ({findInput, changeFilterProject}) => {
    const breakpoint = useMediaQuery(theme.breakpoints.down('sm'))
    const [activeAgeSettings, setActiveAgeSettings] = React.useState<string>('Конкретная дата')
    const [checkedProjects, setCheckedProjects] = React.useState<ICheckedProjects>({
        mobile: true,
        desktop: true
    })
    const [ageInput, setAgeInput] = React.useState<IAgeInput>({
        before: '',
        after: '',
        date: ''
    })

    /**Функция для изменения стейта 'Тип проекта' в блоке: Фильтрация > Тип проекта
     * @param state - 'mobile' | 'desktop'
     * */
    const changeCheckedProjects = (state: typeProjects) => {
        setCheckedProjects({...checkedProjects, [state]: !checkedProjects[state]})
    }

    /**Функция для изменения инпутов 'Год проекта' | 'До' | 'После' в блоке: Фильтрация > Год создания
     * @param event
     * @param title -                  'date' | 'after' | 'before'
     * */
    const changeAgeInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, title: typeAgeTitle) => {
        setAgeInput({...ageInput, [title]: event.target.value})
    }

    /**Функция для изменения активной кнопки в блоке:  Фильтрация > Год создания
     * @param title - 'Конкретная дата' | 'Промежуток'
     * */
    const changeActiveAgeSettings = (title: typeTitle) => {
        setActiveAgeSettings(title)
    }

    React.useEffect(() => {
        const allProject = checkProjects(checkedProjects)

        changeFilterProject(allProject.filter((element) => checkFindInput(element, findInput) && checkAgeInput(element, ageInput)))
    }, [findInput, checkedProjects, ageInput])

    React.useEffect(() => {
        setAgeInput({
            before: '',
            after: '',
            date: ''
        })
    }, [activeAgeSettings])

    return (
        <Box component='div'>
            <Paper elevation={9}>
                <Box component='div' p='20px' display='flex' flexDirection='column' minWidth='266px' maxWidth='266px'>
                    <Typography variant='subtitle1' color='text.secondary'
                                className='subtitle'>Фильтрация</Typography>
                    <Box component='div'>
                        <Box component='div'>
                            <Typography className='subtitle' textAlign='left'
                                        variant='subtitle2'>Тип проекта</Typography>
                        </Box>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={checkedProjects.mobile}
                                                 onChange={() => changeCheckedProjects('mobile')}/>}
                                label="Мобильные"/>
                            <FormControlLabel
                                control={<Switch checked={checkedProjects.desktop}
                                                 onChange={() => changeCheckedProjects('desktop')}/>}
                                label="Десктопные"/>
                        </FormGroup>
                    </Box>

                    <Box component='div'>

                        <Box component='div' display='flex' alignItems='center' justifyContent='space-between' mb='10px'>
                            <Box component='div'>
                                <Typography className='subtitle' textAlign='left'
                                            variant='subtitle2'>Год создания</Typography>
                            </Box>
                            <Box component='div' display='flex' gap='5px'>
                                <AgeButton popperText='Конкретная дата'
                                           changeActive={changeActiveAgeSettings}
                                           icon={<FiberManualRecordIcon
                                               color={activeAgeSettings === 'Конкретная дата' ? 'primary' : undefined}
                                               sx={{width: '8px', height: '8px'}}/>}/>
                                <AgeButton popperText='Промежуток'
                                           changeActive={changeActiveAgeSettings}
                                           icon={<LinearScaleIcon fontSize='small'
                                                                  color={activeAgeSettings === 'Промежуток' ? 'primary' : undefined}/>}/>
                            </Box>
                        </Box>
                        <Box component='div'>
                            <Collapse in={activeAgeSettings === 'Конкретная дата'} timeout={300} unmountOnExit>
                                <Paper elevation={9}>
                                    <CustomTextField
                                        value={ageInput.date}
                                        onChange={(e) => changeAgeInput(e, 'date')}
                                        label='Год проекта'
                                        type='text'
                                        InputProps={{
                                            inputComponent: TextMaskCustom as any
                                        }}
                                        variant='outlined'
                                        color='primary'
                                        size={breakpoint ? 'small' : 'medium'}
                                    />
                                </Paper>
                            </Collapse>
                            <Collapse in={activeAgeSettings === 'Промежуток'} timeout={300} unmountOnExit>
                                <Box component='div' display='flex' gap='5px'>
                                    <Paper elevation={9}>
                                        <CustomTextField
                                            value={ageInput.after}
                                            onChange={(e) => changeAgeInput(e, 'after')}
                                            label='От'
                                            type='text'
                                            InputProps={{
                                                inputComponent: TextMaskCustom as any
                                            }}
                                            variant='outlined'
                                            color='primary'
                                            size={breakpoint ? 'small' : 'medium'}
                                        />
                                    </Paper>
                                    <Paper elevation={9}>
                                        <CustomTextField
                                            value={ageInput.before}
                                            onChange={(e) => changeAgeInput(e, 'before')}
                                            label='До'
                                            type='text'
                                            InputProps={{
                                                inputComponent: TextMaskCustom as any
                                            }}
                                            variant='outlined'
                                            color='primary'
                                            size={breakpoint ? 'small' : 'medium'}
                                        />
                                    </Paper>
                                </Box>
                            </Collapse>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
};

export default FilterMenu;