import React from 'react';
import {Box, Typography, Divider, useMediaQuery} from "@mui/material";
import ContainerLayouts from "../../layouts/ContainerLayouts";
import {theme} from "../../theme/theme";
import {IListTabs} from './footer.interface'
import {useAppDispatch} from "../../hooks/useTypeSelector";
import {changeLink} from "../../store/reducers/headerReducer/headerReducer";
import {NavLink as Link} from "react-router-dom";

const listTabs: IListTabs[] = [
    {title: 'Главная', link: '/'},
    {title: 'О нас', link: '/AboutUs'},
    {title: 'Внедрение Bitrix24', link: 'https://b24.itpw.ru/', isExternal: true},
    {title: 'Портфолио', link: '/Portfolio'},
    {title: 'Контакты', link: '/Contacts'},
]


const Footer = () => {
    const breakpoint = useMediaQuery(theme.breakpoints.down('sm'))
    const dispatch = useAppDispatch()
    const changePage = (title: string) => {
        dispatch(changeLink(title))
    }

    return (
        <Box component='div' bgcolor='#2A2A2A' p={!breakpoint ? '30px 0' : '10px 0'} display='flex' flexDirection='column'>
            <ContainerLayouts>
                <Box component='div' width='100%' display='flex' flexDirection='column'>
                    <Box component='div' display='flex' gap='10px' mb='10px' alignItems='center' flexWrap='wrap'>
                        {listTabs.map((element, index) => (
                            <>
                                {
                                    element?.isExternal ? (
                                        <Box component='div' key={index} display='flex' gap='20px' alignItems='center'>
                                            <a href={element.link} target={'_blank'}>
                                                <Typography className='footer__title'
                                                            color='white'
                                                            fontSize='1rem'>{element.title}</Typography>
                                            </a>
                                            {listTabs.length - 1 !== index ?
                                                <Divider orientation="vertical" sx={{height: '15px'}} flexItem
                                                         variant='middle'/> : ''
                                            }
                                        </Box>
                                    ) : (
                                        <Box component='div' key={index} display='flex' gap='20px' alignItems='center'>
                                            <Link to={element.link}>
                                                <Typography onClick={() => changePage(element.title)} className='footer__title'
                                                            color='white'
                                                            fontSize='1rem'>{element.title}</Typography>
                                            </Link>
                                            {listTabs.length - 1 !== index ?
                                                <Divider orientation="vertical" sx={{height: '15px'}} flexItem
                                                         variant='middle'/> : ''
                                            }
                                        </Box>
                                    )
                                }
                            </>
                        ))}
                    </Box>
                    <Divider/>
                    <Box component='div' mb='10px'>
                        <Divider/>
                    </Box>
                    <Typography className='footer__title' color='white' fontSize='1rem'>IT-Power © Copyright
                        2024</Typography>
                </Box>
            </ContainerLayouts>
        </Box>
    );
};

export default Footer;