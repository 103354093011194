import React from 'react';
import ContainerLayouts from "../../layouts/ContainerLayouts";
import FadeLayouts from "../../layouts/FadeLayouts";
import {Box, Typography} from "@mui/material";
import {IContainerWrapper} from "./containerWrapper.interface";

const ContainerWrapper: React.FC<IContainerWrapper> = ({children, title, fadeLayout, breadcrumbs = null}) => {
    return (
        <ContainerLayouts>
            <FadeLayouts fade={fadeLayout}>
                <Box component='div' id={fadeLayout} width='100%' mt='100px'>
                    {breadcrumbs}
                    <Box component='div' mb='10px'>
                        <Typography className='title text-shadow' textAlign='center' variant='h2'>
                            {title}
                        </Typography>
                    </Box>
                    {children}
                </Box>
            </FadeLayouts>
        </ContainerLayouts>
    );
};

export default ContainerWrapper;