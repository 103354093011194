import React from 'react';
import {Box, Typography} from '@mui/material'
import FadeLayouts from '../../../../layouts/FadeLayouts'
import ContainerLayouts from '../../../../layouts/ContainerLayouts'
import SliceContainer from "./SliceContainer";

const mock = [
    {
        title: "NaSutkiCalendar",
        description: "Мобильное приложение для риэлторов и людей сдающих квартиры посуточно, в котором реализован функционал управления заселением постояльцев, просмотра черного списка клиентов, ведения нескольких квартир, звонков клиенту из приложения, а также просмотр доходов за  каждый месяц.",
        dateAt: 2021
    },
    {
        title: "KOMANDIROVKA.LIFE",
        description: "Приложение, созданное для того, чтобы люди в командировке могли познакомиться с другими командировочными, находящимися в одном городе. В приложении пользователь может найти человека для совместного времяпрепровождения по интересам, возрасту и полу и начать с ним общение через чат, оплатив подписку.",
        dateAt: 2022
    },
    {
        title: "Work Tracker",
        description: "Cистема учета рабочего времени сотрудников, разработанная для внедрения в компанию одного из наших клиентов. В системе реализован функционал Google календаря с расширенными аналитическими возможностями и возможностью разделять исполнителей задач и мероприятий по отдельным проектам, и группам должностей и обязанностей. Проект включает в себя административную панель настройки конкретной компании, в которой можно выставить роли администраторов, контролеров и исполнителей задач.",
        dateAt: 2022
    },
    {
        title: "ASIST",
        description: "Удобный интерфейс для поиска аналогов по заранее составленной смете. Приложение позволяет загрузить в него файл со списком необходимого оборудования по заранее скачанному шаблону и получить в ответ заполненный файл с аналогами.",
        dateAt: 2022
    },
    {
        title: "Gastronorma",
        description: "Мобильное приложение для ресторанов и кафе. Подойдет как для больших сетей, так и для маленьких заведений. В приложении реализованы функции доставки и самовывоза, а также заказа на месте. Приложение Gastronorma - современная замена бумажным блокнотам с интегрированной системой лояльности.",
        dateAt: 2022
    },
    {
        title: "Sop Sop",
        description: "Мобильное приложение для beauty-мастеров, призванное упростить ведение клиентской записи и привлекать больше новых клиентов. В приложении можно выстроить удобный график, вести клиентскую базу и следить за записями на услуги в удобном таймлайне.",
        dateAt: 2022
    },
    {
        title: "Slog",
        description: "Мобильные приложения для людей, которые хотят уменьшить свой экослед, сдавая упаковку и другое вторсырье на переработку. Приложения разработаны в сотрудничестве с компаниями по переработке отходов.",
        dateAt: 2022
    },
]


const History = () => {
    return (
        <ContainerLayouts>
            <FadeLayouts fade='history'>
                <Box component='div' id='history' width='100%' mt='100px'>
                    <Box component='div' mb='10px'>
                        <Typography className='title text-shadow' textAlign='center' variant='h2'>Наша история</Typography>
                    </Box>
                    <SliceContainer array={mock} increment={2} startCount={4}/>
                </Box>
            </FadeLayouts>
        </ContainerLayouts>
    );
};

export default History