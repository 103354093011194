import React from 'react';
import {Line} from 'react-chartjs-2';
import {
    Chart as ChartJS,
    registerables
} from 'chart.js';
import {IGraphic} from "./graphic.interface";

ChartJS.register(...registerables);


const VerticalChart: React.FC<IGraphic> = ({labels, title, dataOne, dataTwo, colorOne, colorTwo}) => {

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                display: true
            },

            subtitle: {
                display: true
            },
            title: {
                display: true,
                text: title
            }
        },
        scales: {
            y: {
                display: true // Hide Y labels
            },
            x: {
                display: true // Hide X labels
            }
        }


    };

    const data = {
            labels: labels,
            datasets: [
                {
                    fill: true,
                    label: 'Активных пользователей',
                    tension: 0.5,
                    pointRadius: 4,
                    grouped: false,
                    order: 1,
                    data: dataOne,
                    backgroundColor: (context: any) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 500);
                        gradient.addColorStop(0, colorOne[0]);
                        gradient.addColorStop(1, colorOne[1]);
                        return gradient;
                    },
                    borderColor: 'white',
                    pointBorderColor: 'white',
                    hoverPointRadius: 6,
                    hoverBorderColor: '#5E35B199',
                    borderWidth: 1
                },
                {
                    fill: true,
                    label: 'Успешные подборы аналогов',
                    tension: 0.5,
                    pointRadius: 4,
                    order: 2,
                    data: dataTwo,
                    backgroundColor: (context: any) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 500);
                        gradient.addColorStop(0, colorTwo[0]);
                        gradient.addColorStop(1, colorTwo[1]);
                        return gradient;
                    },
                    borderColor: '#9ec2ef',
                    pointBorderColor: 'white',
                    hoverBorderColor: '#9ec2ef',
                    borderWidth: 1

                },
            ]
        }
    ;

    return (
        <Line options={options as any} data={data as any}/>
    );
}

export default VerticalChart;
