import React from 'react';
import {Box, Button, Typography, Fade} from '@mui/material'


interface IAddMoreLayouts {
    startCount: number,
    increment: number
    array: any[]
}

const findOutNumberIsOdd = (index: number) => {
    return index % 2 === 0
}

const SliceContainer: React.FC<IAddMoreLayouts> = ({startCount, increment, array}) => {
    const [count, setCount] = React.useState<number>(startCount)
    const incrementCount = () => {
        setCount(state => state + increment)
    }
    const sliceArray = array.slice(0, count)

    return (
        <Box component='div' display='flex' justifyContent='center' flexDirection='column' alignItems='center'>
            {sliceArray.map((element, index) => (
                <Fade key={index} in={true}>
                    <Box component='div' display='flex' justifyContent='center' flexDirection='column'
                         alignItems='center'
                         position='relative'>
                        <Box component='div' className='history__circle'/>
                        <Box component='div' className={findOutNumberIsOdd(index) ? 'history__box' : 'history__box history__box--left'}>
                            <Box component='div'
                                className={findOutNumberIsOdd(index) ? 'history__year' : 'history__year history__year--left'}>
                                <Typography variant='subtitle2' color='primary.main'>
                                    {element.dateAt}
                                </Typography>
                            </Box>
                            <Box component='div'
                                className={findOutNumberIsOdd(index) ? 'history__title' : 'history__title history__info--left'}>
                                <Typography  variant='h6'>
                                    {element.title}
                                </Typography>
                            </Box>
                            <Box component='div'
                                className={findOutNumberIsOdd(index) ? 'history__subtitle' : 'history__subtitle history__info--left'}>
                                <Typography variant='subtitle2' fontSize='0.9rem'>
                                    {element.description}
                                </Typography>
                            </Box>
                        </Box>
                        <Box component='div' className='history__line'/>
                    </Box>
                </Fade>
            ))}
            <Box component='div'>
                <Button disabled={sliceArray.length === array.length} onClick={incrementCount}>Еще</Button>
            </Box>
        </Box>
    )
}

export default SliceContainer;
