import React from 'react';
import {History, WhyWe, Services} from './blocks/index'


const AboutUs = () => {
    return (
        <>
            <WhyWe/>
            <Services/>
            <History/>
        </>
    );
};

export default AboutUs;